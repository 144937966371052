@function resizeFlow($value, $add: 0) {
	@return ($value + $add) * $flow-multiplier * 1px;
}

.flow-content-dialog {
	height: 340px;
	overflow-y: auto;
	//margin-top: 10px;
	width: 100%;

	.flow-content-dialog-content {
		height: 100%;
	}
}

.modal-content-dialog {
	height: 300px;
	overflow-y: auto;
	border-top: 0;
	.modal-content-dialog-content {
		height: 100%;
	}

	ul {
		margin-top: 0;
	}
}

mat-stepper {
	display: block;
}

.mat-steppers-header,
.mat-steppers-mobile-header,
.mat-step-actions {
	-webkit-flex: 0 0 auto;
	-ms-flex: 0 0 auto;
	flex: 0 0 auto;
	box-sizing: border-box;
}

.mat-steppers-header-region {
	z-index: 1;
}

@media (max-width: 599px) {
	.mat-stepper {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
	}
	.mat-stepper > div {
		min-width: 0px;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}
	.mat-steppers-content {
		min-height: 0px;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.mat-step.mat-active {
		min-height: 0px;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
	}
	.mat-step.mat-active .mat-stepper {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-flex-direction: column;
		-ms-flex-direction: column;
		flex-direction: column;
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
		min-height: 0px;
	}
	.mat-steppers-scope {
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}
	.mat-step-body {
		-webkit-flex: 1;
		-ms-flex: 1;
		flex: 1;
	}
}

.mat-steppers {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-flow: column;
	-ms-flex-flow: column;
	flex-flow: column;
}

.mat-steppers:not(.mat-steppers-linear)
	.mat-stepper-indicator:not(.mat-active):not(.mat-completed):hover,
.mat-steppers:not(.mat-steppers-linear)
	.mat-stepper-indicator.mat-editable.mat-completed:hover {
	cursor: pointer;
}

.mat-steppers-header {
	margin: 0;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-flow: row nowrap;
	-ms-flex-flow: row nowrap;
	flex-flow: row nowrap;
	flex-wrap: wrap;
	border-radius: 0;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
}

.mat-stepper-alternate {
	min-height: 104px;

	.mat-stepper-indicator {
		margin-top: 24px;
		margin-bottom: 24px;
		min-height: 56px;

		.main-container {
			min-height: 56px;

			.mat-stepper-number {
				height: 24px;
				width: 24px;
			}

			.mat-stepper-title {
				margin-top: 16px;
			}
		}
	}
}

.card-flow-wrapper-8 {
	.mat-stepper-alternate {
		.mat-stepper-indicator {
			margin-bottom: 8px;
		}
	}
}

.card-flow-wrapper-horizontal-8 {
	.mat-steppers-horizontal .mat-stepper-indicator {
		padding-bottom: 8px !important;
	}
}

.card-flow-wrapper-16 {
	.mat-stepper-alternate {
		.mat-stepper-indicator {
			margin-bottom: 16px;
		}
	}
}

.card-flow-wrapper-0 {
	.mat-stepper-alternate {
		.mat-stepper-indicator {
			margin-bottom: 0px;
		}
	}
}

.order-flow .mat-steppers-horizontal .mat-stepper-title.alt-line-height {
	line-height: 18px;
}

.mat-steppers-horizontal {
	&:not(.mat-stepper-alternate) {
		.mat-stepper-indicator {
			padding-top: 24px;
			padding-bottom: 24px;
		}
	}
}

.mat-steppers-horizontal
	.mat-stepper-indicator:first-child
	.mat-stepper-indicator-wrapper {
	padding-left: 16px;
}

.mat-steppers-horizontal .mat-stepper-indicator:after {
	width: 999em;
	height: 1px;
	margin-top: -1px;
	position: absolute;
	top: 36px;
	left: 0;
	z-index: 1;
	content: ' ';
}

.mat-stepper-indicator {
	padding: 0 16px;
	position: relative;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	-webkit-align-content: flex-start;
	-ms-flex-line-pack: start;
	align-content: flex-start;
	-webkit-align-items: flex-start;
	-ms-flex-align: start;
	align-items: flex-start;
	overflow: hidden;
	cursor: pointer;
	background: none;
	border: none;
	transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.mat-stepper-indicator:focus {
	outline: none;
}

.mat-stepper-indicator.mat-active .mat-stepper-title,
.mat-stepper-indicator.mat-editable .mat-stepper-title {
	font-weight: 500;
}

.mdl-stepper-horizontal-alternative
	.mdl-stepper-step
	.mdl-stepper-circle
	.mdl-stepper-icon
	.material-icons {
	font-size: 16px !important;
	line-height: 1.5em !important;
}

.mat-stepper-indicator:first-child {
	padding-left: 0;
}

.mat-stepper-indicator:last-child {
	padding-right: 0;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: end;
	justify-content: flex-end;
}

.mat-stepper-indicator:last-child .mat-stepper-indicator-wrapper {
	padding-right: 24px;
}

.mat-stepper-indicator .mat-ripple-container {
	z-index: 3;
}

.mat-stepper-indicator-wrapper {
	padding: 0 8px 0 0;
	position: relative;
	z-index: 2;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
	justify-content: center;
	font-size: 0.9em;
	transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
}

.mat-stepper-number {
	width: 24px;
	height: 24px;
	margin: 0 8px;
	border-radius: 24px;
	transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
	font-size: 12px;
	line-height: 24px;
	text-align: center;
}

.mat-stepper-number.ng-hide {
	transition: none;
}

.mat-error .mat-stepper-error-indicator {
	margin: 0 8px;
}

.mat-stepper-number mat-icon {
	font-size: 18px;
	margin-top: 4px;
}

.mat-error .mat-stepper-error-message {
	font-weight: 400;
}

.mat-stepper-icon {
	width: 24px;
	height: 24px;
	position: relative;
	top: -1px;
	margin: 0 8px;
	font-size: 18px;
	font-weight: 700;
}

.mat-stepper-icon.mat-stepper-icon-edit {
	width: 14px;
	height: 14px;
	font-size: 14px;
	font-weight: 400;
}

.mat-stepper-title {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-flow: column nowrap;
	-ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
	position: relative;
	transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
	font-size: 13px;
	line-height: 24px;
}

.mat-steppers-content {
	position: relative;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
}

.mat-stepper {
	box-sizing: border-box;
}

.mat-stepper.mat-active {
	position: relative;
	pointer-events: auto;
}

.mat-steppers-actions {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

.mat-stepper-optional .mat-stepper-title {
	text-align: left;
	white-space: nowrap;
}

.mat-stepper-optional .mat-stepper-title small {
	transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
	font-size: 12px;
	line-height: 1em;
}

.mat-steppers-linear .mat-stepper-indicator {
	cursor: default;
}

.mat-steppers-linear .mat-stepper-indicator.mat-editable.mat-completed:hover {
	cursor: pointer;
}

.mat-steppers-alternative:not(.mat-steppers-linear)
	.mat-stepper-indicator:not(.mat-active):not(.mat-completed):hover
	.mat-stepper-indicator-wrapper {
	background: none;
}

.mat-steppers-alternative .mat-steppers-horizontal .mat-stepper-indicator {
	min-height: 104px;
	padding: 24px 16px;
	-webkit-flex: 1;
	-ms-flex: 1;
	flex: 1;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.mat-steppers-alternative
	.mat-steppers-horizontal
	.mat-stepper-indicator
	.mat-stepper-indicator-wrapper {
	padding: 0;
}

.mat-steppers-alternative
	.mat-steppers-horizontal
	.mat-stepper-indicator:first-child {
	padding-left: 24px;
}

.mat-steppers-alternative
	.mat-steppers-horizontal
	.mat-stepper-indicator:first-child:after {
	left: 50%;
}

.mat-steppers-alternative
	.mat-steppers-horizontal
	.mat-stepper-indicator:last-child {
	padding-right: 24px;
}

.mat-steppers-alternative
	.mat-steppers-horizontal
	.mat-stepper-indicator:last-child:after {
	right: 50%;
	left: auto;
}

.mat-steppers-alternative
	.mat-steppers-horizontal
	.mat-stepper-indicator:after {
	margin-top: 0;
	top: 36px;
}

.mat-steppers-alternative
	.mat-steppers-horizontal
	.mat-stepper-indicator-wrapper {
	padding: 0 16px;
	min-height: 50px;
	-webkit-flex-flow: column nowrap;
	-ms-flex-flow: column nowrap;
	flex-flow: column nowrap;
	background: none;
}

.mat-steppers-alternative .mat-steppers-horizontal .mat-stepper-number,
.mat-steppers-alternative
	.mat-steppers-horizontal
	.mat-stepper-error-indicator {
	position: relative;
}

.mat-steppers-alternative .mat-steppers-horizontal .mat-stepper-number:before,
.mat-steppers-horizontal .mat-stepper-error-indicator:before,
.mat-steppers-alternative .mat-steppers-horizontal .mat-stepper-number:after,
.mat-steppers-horizontal .mat-stepper-error-indicator:after {
	width: 8px;
	position: absolute;
	top: 0;
	bottom: 0;
	transition: all 0.4s cubic-bezier(0.25, 0.8, 0.25, 1);
	content: ' ';
}

.mat-steppers-alternative .mat-steppers-horizontal .mat-stepper-number:before,
.mat-steppers-alternative
	.mat-steppers-horizontal
	.mat-stepper-error-indicator:before {
	left: -8px;
}

.mat-steppers-alternative .mat-steppers-horizontal .mat-stepper-number:after,
.mat-steppers-alternative
	.mat-steppers-horizontal
	.mat-stepper-error-indicator:after {
	right: -8px;
}

.mat-steppers-alternative .mat-steppers-horizontal .mat-stepper-title {
	margin-top: 16px;
	line-height: 18px;
	text-align: center;
}

.mat-steppers-alternative .mat-steppers-horizontal .mat-stepper-title small {
	text-align: center;
}

.mat-steppers:not(.mat-steppers-vertical)
	.mat-steppers-header.mat-steppers-vertical {
	display: none;
}

.mat-steppers-vertical .mat-stepper {
	padding: 8px 24px;
}

.mat-steppers-vertical .mat-steppers-scope {
	margin-left: 20px;
	padding-left: 20px;
}

.mat-steppers-vertical .mat-steppers-header.mat-steppers-horizontal {
	display: none;
}

.mat-steppers-mobile-header,
.mat-stepper-feedback-message {
	display: none;
}

@media (max-width: 599px) {
	.mat-steppers-mobile-step-text:not(.mat-steppers-vertical) .mat-stepper {
		padding: 0;
	}
	.mat-steppers-mobile-step-text:not(.mat-steppers-vertical)
		.mat-steppers-mobile-header {
		display: block;
	}
	.mat-steppers-mobile-step-text:not(.mat-steppers-vertical)
		.mat-stepper-feedback-message {
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
	}
	.mat-steppers-mobile-step-text:not(.mat-steppers-vertical)
		.mat-steppers-header.mat-steppers-horizontal {
		display: none;
	}
	.mat-steppers-mobile-step-text:not(.mat-steppers-vertical) mat-step-actions {
		-webkit-flex-direction: row;
		-ms-flex-direction: row;
		flex-direction: row;
		box-sizing: border-box;
		display: -webkit-flex;
		display: -ms-flexbox;
		display: flex;
		-webkit-align-content: stretch;
		-ms-flex-line-pack: stretch;
		align-content: stretch;
		-webkit-align-items: stretch;
		-ms-flex-align: stretch;
		align-items: stretch;
		-webkit-justify-content: space-between;
		-ms-flex-pack: justify;
		justify-content: space-between;
	}
}

.mat-steppers-header-region {
	position: relative;
}

.mat-stepper-feedback-message {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	-webkit-align-items: center;
	-ms-flex-align: center;
	-ms-grid-row-align: center;
	align-items: center;
	padding-left: 10px;
	z-index: 2;
	box-sizing: border-box;
}

.mat-steppers {
	position: relative;
}

mat-step-body {
	position: relative;
	overflow: auto;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
}

.mat-step-body-loading {
	display: none;
}

.mat-steppers-has-feedback .mat-steppers-overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 99;
}

.mat-steppers-has-feedback .mat-step-body-overlay {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 90;
	opacity: 0.75;
}

.mat-steppers-has-feedback .mat-step-body-loading {
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	width: 100%;
	z-index: 91;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
	flex-direction: column;
	box-sizing: border-box;
	-webkit-align-items: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-align-content: center;
	-ms-flex-line-pack: center;
	align-content: center;
	max-width: 100%;
	-webkit-justify-content: center;
	-ms-flex-pack: center;
	justify-content: center;
}

.ca-order-service-accordion {
	.mat-stepper-alternate {
		min-height: 88px;
	}

	.mat-stepper-alternate .mat-stepper-indicator {
		margin-top: 6px;
	}

	.card-flow-wrapper-16 .mat-stepper-alternate .mat-stepper-indicator {
		margin-bottom: 14px;
	}
}

.srm-flow div.matl-stepper-optional {
	position: unset !important;
}

.matl-stepper-optional {
	text-align: center;
	top: 80px;
	position: fixed;
}

.matl-stepper-horizontal-alternative .matl-stepper-step.active-step {
	.matl-stepper-title {
		font-weight: 500;
	}
	&.step-done .matl-stepper-title,
	&.editable-step .matl-stepper-title {
		font-weight: 300;
	}
}

.matl-stepper-step .matl-stepper-optional {
	font-size: 12px;
}

.matl-stepper-horizontal-alternative .matl-stepper-step {
	.matl-stepper-bar-left {
		position: absolute;
		top: 25px;
		height: 1px;
		border-top-width: 1px;
		border-top-style: solid;
	}
	.matl-stepper-bar-right {
		position: absolute;
		top: 25px;
		height: 1px;
		border-top-width: 1px;
		border-top-style: solid;
		right: 0;
		left: 50%;
		margin-left: 20px;
	}
	.matl-stepper-bar-left {
		left: 0;
		right: 50%;
		margin-right: 20px;
	}
}

.line-fix {
	&:first-of-type:after {
		left: 50%;
	}
	&:last-of-type:after {
		left: unset;
		right: 50%;
	}
	&:after {
		top: 12px;
	}
	justify-content: center;
}

.current-step {
	font-weight: bold;
}

.flow-title {
	height: resizeFlow(16);
	line-height: resizeFlow(16);
	font-size: $font_size_body2 !important;
}

.flow-date {
	height: 16px;
	line-height: 16px;
	opacity: 0.6;
	font-size: $font_size_body2 !important;
}

.mat-step-header {
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
	.mat-step-label,
	.mat-step-optional {
		display: flex !important;
		flex-direction: column !important;
		align-items: center !important;
	}
}

.mat-horizontal-stepper-header,
.mat-step-header .mat-step-label.mat-step-label-active {
	display: flex !important;
	flex-direction: column !important;
	align-items: center !important;
}

.mat-stepper-horizontal,
.mat-stepper-vertical {
	background-color: none !important;
}

/* Simple setup for this demo */

.mdl-card {
	width: 100%;
	min-height: 0;
	margin: 4px auto 16px;
}

.mdl-card__supporting-text {
	width: 100%;
	padding: 0;
}

/* .mdl-stepper-horizontal-alternative .mdl-stepper-step {
  width: 25%;
} */

/* Begin actual mdl-stepper css styles */

.mdl-stepper-horizontal-alternative {
	display: table;
	width: 100%;
	margin: 0 auto;
	.mdl-stepper-step {
		display: table-cell;
		position: relative;
		padding: resizeFlow(24) resizeFlow(24) resizeFlow(0) resizeFlow(24);
		cursor: pointer;
		// &:hover {
		//   background-color: rgba(0, 0, 0, 0.06);
		// }
		&:active {
			// background-color: rgba(0, 0, 0, 0.06);
			border-radius: 15% / 75%;
		}
		&:first-child:active {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
		&:last-child:active {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		&:hover .mdl-stepper-circle {
			background-color: #757575;
		}
		&:first-child .mdl-stepper-bar-left,
		&:last-child .mdl-stepper-bar-right {
			display: none;
		}
		.mdl-stepper-circle {
			width: 24px;
			height: 24px;
			margin: 0 auto;
			/* background-color: #9E9E9E; */
			border-radius: 50%;
			text-align: center;
			/* line-height: 1.9em;
      font-size: 14px; */
			display: flex;
			align-items: center;
			justify-content: center;
			color: white;
		}
		&.step-done .mdl-stepper-circle {
			&:before {
				content: '\2714';
			}
			* {
				display: none;
			}
		}
		&.editable-step .mdl-stepper-circle {
			* {
				display: none;
			}
			-moz-transform: scaleX(-1);
			/* Gecko */
			-o-transform: scaleX(-1);
			/* Opera */
			-webkit-transform: scaleX(-1);
			/* Webkit */
			transform: scaleX(-1);
			/* Standard */
			&:before {
				content: '\270E';
			}
		}
		.mdl-stepper-title {
			margin-top: 16px;
			/* font-size: 14px;
      font-weight: normal; */
			text-align: center;
			/* color: rgba(0, 0, 0, .26); */
		}
		.mdl-stepper-optional {
			text-align: center;
			/* color: rgba(0, 0, 0, .26); */
		}
		&.active-step .mdl-stepper-title {
			color: rgba(0, 0, 0, 0.87);
		}
		&.current-step .mdl-stepper-title {
			color: rgba(0, 0, 0, 0.87);
		}
		&.active-step {
			&.step-done .mdl-stepper-title,
			&.editable-step .mdl-stepper-title {
				font-weight: 300;
			}
		}
		.mdl-stepper-optional {
			font-size: $font_size_caption !important;
		}
		&.active-step .mdl-stepper-optional {
			color: rgba(0, 0, 0, 0.54);
		}
		.mdl-stepper-bar-left {
			position: absolute;
			top: 36px;
			height: 1px;
			/* border-top: 1px solid #BDBDBD; */
		}
		.mdl-stepper-bar-right {
			position: absolute;
			top: 36px;
			height: 1px;
			/* border-top: 1px solid #BDBDBD; */
			right: 0;
			left: 50%;
			margin-left: 20px;
		}
		.mdl-stepper-bar-left {
			left: 0;
			right: 50%;
			margin-right: 20px;
		}
	}
}

/* .mdl-stepper-horizontal-alternative .mdl-stepper-step.active-step .mdl-stepper-circle {
  background-color: rgb(33, 150, 243);
} */

.stepper-titles {
	display: block;
	text-align: center;
	margin-top: 10px;
	font-size: $font_size_body2 !important;
}

.mdl-stepper-step.disabled {
	opacity: 0.5 !important;
	cursor: default !important;
	pointer-events: none;
}
