@function resizeBar($value, $add: 0) {
  @return ($value + $add) * $bar-multiplier * 1px;
}

.topbar.mat-toolbar.mat-toolbar-single-row {
  padding-left: 0;
  padding-right: 24px !important;
  height: resizeBar(64) !important;
  display: flex;
  vertical-align: middle;

  .nav-toggle-wrapper {
    padding-left: resizeBar(24);
    padding-right: resizeBar(24);
  }

  .mat-toolbar-layout {
    height: 100%;
    .mat-toolbar-row {
      height: 100% !important;
    }
  }

  .avatar {
    width: resizeBar(44) !important;
    height: resizeBar(44) !important;
    line-height: resizeBar(44) !important;
  }

  .logo {
    width: resizeBar(210);
    height: resizeBar(33);
    background-position: left -1px;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.topbar .nav-toggle {
  height: 40px;
  width: 24px;
  mat-icon {
    align-items: center;
    display: flex;
    left: 0;
    height: 24px;
    width: 24px;
    justify-content: center;
    position: relative;
    vertical-align: middle;
  }
}
