@import "../variables";

/* mat Option is not part of form, its part of cdk-overlay
 This bellow is global settings for mat option
 to isolate ca-form case need to apply e.g. ca-form-mat-option class to each mat-option
 .ca-mat-option*/
.mat-option {
  text-transform: none !important;
  font-size: $ca-form-font-size !important;
  font-family: $ca-form-font-family !important;
  padding: 0 1em !important;
  height: 3em !important;
  line-height: 3em !important;

  &.two-line {
    height: 3em !important;
    line-height: 1.5em !important;
    padding-top: 2em !important;
    padding-bottom: 2.1em !important;

    span.second-line {
      display: block;
      line-height: $ca-form-font-size;
      opacity: 0.6;
      font-size: $font_size_caption;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
}

.customer-inv-grid-site-address, .inv-grid-site-address  {
  line-height: 12px;
  opacity: 0.6;
  font-size: $font_size_caption !important;
}

.mat-form-field-hide-placeholder .mat-select-placeholder, .ca-card-filter .mat-form-field-hide-placeholder .mat-select-placeholder {
  color: transparent !important;
}

.ca-form {

  .mat-mdc-form-field.mat-primary {
    background-color: transparent !important;
    color: inherit !important;
  }


  .mat-datepicker-toggle {

    .mdc-icon-button {
        height: 2em !important;
        width: 2em !important;
    }

    .mat-icon {
      height: 1.5em !important;
      line-height: 1.5em !important;
      width: 1.25em !important;
    }
  }

  min-height: 40px;

  .flex-container .flex-item {
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    min-height: 55px;
  }

  .flex-container .flex-item.billing-alias-inventory-manage-dialog {
    min-height: 40px !important;
  }

  .flex-item-inline {
    padding-left: $ca-form-flex-item-spacing;
  }

  .ca-mat-select-inline {
    padding-top: 0 !important;
    padding-bottom: 0 !important;

    mat-select {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }

  .ca-form-input-inline .mat-mdc-form-field-infix {
    border-top: 0;
  }

  font-size: $ca-form-font-size;
  font-family: $ca-form-font-family;

  /* matInput */
  mat-form-field {
    font-family: $ca-form-font-family !important;
    //padding-top: 1em;
    width: 100%;
  }

  /* mat-select */
  mat-select {
    display: block !important;
    font-family: $ca-form-font-family;
  }
  .mat-mdc-select-trigger {
    text-transform: none;
    font-size: $ca-form-font-size;
    min-width: 4em;
  }

  /* Hint */
  .mat-hint {
    font-size: $font_size_caption; // Important to cover mat-hint font size for mat-select
    font-family: $font-family;
    &.ca-select-hint {
      position: absolute;
    }
  }

  /* Checkbox */
  .mat-checkbox {
    font-family: $ca-form-font-family;
    font-size: $ca-form-font-size;
  }

  li {
    mat-slide-toggle {
      padding-left: 0em;
      padding-bottom: 0em;
    }
  }

  /* mat-slide-toggle */
  mat-slide-toggle {
    padding-left: 1.1em;
    margin-bottom: 24px;
    &.single-row {
      padding-bottom: 1.25em;
    }
    &.single-row-centered {
      padding: calc(2.54em - 12px) 0 !important;
    }
    .mat-slide-toggle-content {
      font-family: $ca-form-font-family !important;
      font-size: resize(15) !important;
    }
  }

  mat-slide-toggle.ca-toggle-srm-list-padding-adjust {
    padding-top: 0;
    padding-bottom: 1.25em;
    padding-left: 0 !important;
  }

  /* Radio */
  mat-radio-button.mat-mdc-radio-button {
    &.mat-accent {
      font-family: $ca-form-font-family !important;
      font-size: $ca-form-font-size !important;
      background-color: red !important;
      color: inherit !important;
    }
    .mat-accent {
      background-color: red !important;
    }
    .mat-radio-label {
      padding-top: calc(3.8125em - 20px);
    }
  }

  .mat-input-container {
    font-family: $ca-form-font-family;
    font-size: $ca-form-font-size;
  }

  /* tag-input */
  tag-input {
    font-family: $ca-form-font-family !important;
    font-size: $ca-form-font-size !important;
    padding: 1.25em 0;
    height: 2.51em;
    &.with-validation {
      padding: 1.25em 0 0 0 !important;
    }

    .ng2-tag-input {
      padding: 0 0 0.4375em 0 !important; // tags and base line spacing as on matInput
      min-height: unset !important;
      border-bottom-width: 1px;
      border-bottom-style: solid;
      &.ng2-tag-input--focused {
        border-bottom-width: 1px;
        border-bottom-style: solid;
      }

      .ng2-tags-container {
        tag {
          font-family: inherit !important;
          font-size: inherit !important;
          height: 2em;
          line-height: 2em;
          margin: 0 0.3em 0 0;
          padding: 0 0.3em 0 0.75em;
          .tag-wrapper {
            .tag__text {
            }
            svg {
              height: 2.125em !important;
            }
          }
        }
        tag-input-form {
          padding-top: 0.4375em !important;
          .ng2-tag-input__text-input {
            font-family: inherit !important;
            font-size: inherit !important;
            height: unset !important;
            padding: 0;
          }
        }
      }
    }
  }

  .mat-chip {
    transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
    display: inline-flex;
    padding: 4px 8px !important;
    border-radius: 24px;
    align-items: center;
    cursor: default;
    font-size: $font_size_body2 !important;
  }

  .mat-chip {
    margin: 0 4px 4px 0!important;
  }
}

.mat-mdc-dialog-actions {
  .ca-form mat-select {
    padding-bottom: 0 !important;
  }
}

.ca-picker-search-container {
  .mat-form-field-underline {
    height: 0 !important;
  }
  &.mat-focused {
    .mat-form-field-underline {
      .mat-form-field-ripple {
        height: 0 !important;
      }
    }
  }
  .mat-mdc-form-field-infix {
    border-bottom-width: 1px;
    border-bottom-style: solid;
  }
}

.ca-picker-search-container .mat-input-underline .mat-input-ripple.mat-focused {
  transform: scaleY(0);
}

.ca-picker-search-container .mat-input-underline {
  height: 0 !important;
}



.filter-form .mat-mdc-form-field {
  width: 100%;
}

.color-picker-preview {
  display: inline-block;
  width: 16px;
  height: 16px;
}

td {
  .mat-select {
    width: 96%;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.ca-form {
  td, th {
    .mat-checkbox label.mat-checkbox-layout {
      padding-top: 0!important;
      padding-bottom: 0 !important;
    }
  }
}

/***
*
* Temporary stiles for netowrk hub
*
***/
.ca-network-hub-form {
  ca-pager {
    mat-select {
      padding-top: 1em;
    }
  }

  ca-date-picker {
    position: relative;
    top: -1px;
  }
}

.ca-network-hub-inventory-filter {
  form {
    margin-top: unset !important;
  }
}

.ca-npa-picker, .ca-nxx-picker {
  height: 50px;
  min-height: unset !important;

  .mat-mdc-form-field {
    padding-top: 0 !important;
  }
}

.ca-table-with-select {
  td {
    padding: 0 10px !important;
  }

  ca-default-picker {
    mat-form-field {
      padding-top: 0 !important;
    }
  }
}

mat-slide-toggle {
  .mat-slide-toggle-content {
    font-family: $ca-form-font-family !important;
    font-size: resize(15) !important;
  }
}
