@function resize($value, $add: 0) {
  @return ($value + $add) * $multiplier * 1px;
}

@function resizeHiDens($value, $add: 0) {
  @return ($value + $add) * $hi-dens-multiplier * 1px;
}

button.mat-mdc-menu-item {
  font-size: $font_size_button !important;
}

.ca-form {
  .mat-mdc-form-field-infix {
    padding: 0px !important;
  }

  .mat-mdc-text-field-wrapper {
    height: resize(44);
  }

  @import "ca-input";
  @import "hi-density-input";

  .table-input-fields {
    .currency-without-label {
      .mat-input-wrapper.mat-mdc-text-field-wrapper {
        padding-bottom: resize(8) !important;
        padding-top: resize(8) !important;
      }
    }
  }

  .report-interactive-filter {
    .mat-input-wrapper.mat-mdc-text-field-wrapper {
      padding-bottom: resize(16) !important;
      padding-top: resize(8) !important;
    }
    .mat-mdc-text-field-wrapper {
      padding-bottom: resize(16) !important;
      padding-top: resize(8) !important;
    }

    &.flex-row {
      .ca-form > * {
        max-width: 250px;

        .date-filter {
          flex-direction: row !important;
          justify-content: space-between !important;
        }
      }
    }
  }

  mat-hint {
    margin-left: resize(12);
    bottom: 3px;
    //ammounts to 12dp guideline for Roboto
    font-size: $font_size_caption !important;
  }

  span.mat-checkbox-label {
    font-size: $font_size_body2;
  }

  .ca-select-hint {
    margin-bottom: resize(8);
  }
}

.tag-filter {
  [hiDensityInput] {
    .mat-chip-list-wrapper {
      border-radius: 2px 2px 0 0;
      min-height: resizeHiDens(42, -12);
    }

    input.mat-chip-input {
      // margin-top: auto;
      //margin-left: resize(12);
      margin-bottom: resizeHiDens(12);
      color: black;
    }

    input.mat-chip-input:only-child {
      margin-top: auto;
      margin-bottom: auto;
    }

    .mat-chip-list-wrapper {
      padding-left: resizeHiDens(8);
    }

    .mat-chip,
    .mat-chip:not(.mat-basic-chip) + .mat-chip:not(.mat-basic-chip) {
      margin: resizeHiDens(8) resizeHiDens(8) 0 0;
    }

    .mat-chip:not(.mat-basic-chip) {
      padding: resizeHiDens(8) resizeHiDens(12);
    }

    .mat-chip:last-of-type {
      margin-bottom: resizeHiDens(8) !important;
    }

    .mat-chip-remove {
      margin-left: resizeHiDens(8);
    }

    .mat-form-field-underline {
      position: unset;
    }

    .mat-input-wrapper {
      .mat-input-underline {
        background: none;
        bottom: 0;
      }
    }
  }
}

.date-filter {
  [hiDensityInput] {
    .mat-mdc-form-field {
      padding-top: 0 !important;
    }

    mat-select {
      //compensation for baseline calculation
      margin-top: resizeHiDens(20) !important;

      .mat-select-arrow-wrapper {
        margin-top: resizeHiDens(6) !important;
      }
    }

    .mdc-text-field {
      .mat-mdc-form-field-infix {
        input {
          margin-top: resizeHiDens(26, -8);
        }

        mat-select {
          //compensation for baseline calculation
          margin-top: resizeHiDens(20, -6) !important;

          label {
            top: resizeHiDens(20, -6) !important;
          }
        }
      }
    }
  }
}

.mat-checkbox-inner-container {
  height: resizeHiDens(26, -6) !important;
  width: resizeHiDens(26, -6) !important;
}

.mat-chip {
  font-size: $font_size_body2 !important;
}

.mat-option-text {
  font-size: $font_size_body2 !important;
}

.caGap {
  .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.caGapRight {
  margin-right: $ca_gap !important;
}

.caGapLeft {
  margin-left: $ca_gap !important;
}
