/* You can add global styles to this file, and also import other style files */
//@import '~@angular/material/core/theming/prebuilt/indigo-pink.css';
//@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "assets/themes/default.scss";

@import "assets/common";
@import "assets/layout";
@import "assets/widgets/dialog";
@import "assets/widgets/toolbar";
@import "assets/widgets/forms";
@import "assets/widgets/grid";
@import "assets/widgets/pager";
@import "assets/widgets/card";
@import "assets/widgets/avatar";
@import "assets/widgets/list";
@import "assets/widgets/table";
@import "assets/widgets/flow";
@import "assets/widgets/buttons";
@import "assets/widgets/tab";
@import "assets/widgets/scrollbar";
@import "assets/widgets/notes";
@import "assets/widgets/ca-form";
@import "assets/widgets/tables.scss";
@import "assets/components/navigation";
@import "assets/components/sidenav";
@import "assets/components/topbar";
@import "assets/widgets/ca-card";
@import "assets/widgets/notification";

@media screen and (max-device-width: 1440px) {
  $multiplier: 0.9;
  $hi-dens-multiplier: 0.9;
  $table-multiplier: 0.9;
  $pager-multiplier: 0.9;
  $nav-multiplier: 0.9;
  $sidenav-multiplier: 0.9;
  $bar-multiplier: 0.9;
  $card-multiplier: 0.9;
  $tab-multiplier: 0.9;
  $flow-multiplier: 0.9;

  // Change variables depending of plan width. Change one global variable in one places with any other variable
  $font_size_h1: $font_size_sm_h1;
  $font_size_h2: $font_size_sm_h2;
  $font_size_h3: $font_size_sm_h3;
  $font_size_h4: $font_size_sm_h4;
  $font_size_h5: $font_size_sm_h5;
  $font_size_h6: $font_size_sm_h6;
  $font_size_subtitle: $font_size_sm_subtitle;
  $font_size_subtitle2: $font_size_sm_subtitle2;
  $font_size_body1: $font_size_sm_body1;
  $font_size_body2: $font_size_sm_body2;
  $font_size_button: $font_size_sm_button;
  $font_size_caption: $font_size_sm_caption;
  $font_size_overline: $font_size_sm_overline;
  $ca_gap: $ca_gap_sm;

  @import "assets/widgets/ca-form";
  @import "assets/widgets/tables.scss";
  @import "assets/widgets/pager";
  @import "assets/components/navigation";
  @import "assets/components/sidenav";
  @import "assets/components/topbar";
  @import "assets/widgets/toolbar";
  @import "assets/widgets/ca-card";
  @import "assets/widgets/card";
  @import "assets/widgets/tab";
  @import "assets/widgets/flow";
  @import "assets/widgets/dialog";
  @import "assets/layout.scss";
  @import "assets/widgets/notification";
}

@import "assets/components/sign-in";
@import "assets/components/theme";
@import "assets/components/dashboard";
@import "assets/components/history";
@import "assets/components/filter";
@import "assets/components/tree-grid";
@import "assets/components/timeline-styles.css";
@import "assets/utility";

//@include angular-material-theme($app-theme);

.active-underscore {
  .mat-mdc-tab-group.mat-accent .mat-ink-bar,
  .mat-tab-nav-bar.mat-accent .mat-ink-bar {
    background-color: #303e4d !important;
  }
}

/* So far we've got font sizes the same as material.io docs */

/* CONVERSIONS */
/* font size */
// SP_SIZE/16 = rem
/* letter spacing */
// (Tracking from Sketch / font size in px) = letter spacing

/* Titles in toolbars were only missing letter spacing */
.toolbar-title,
.toolbar-details-title,
.mat-mdc-dialog-container .ca-dialog-title h1 {
  letter-spacing: 0.0075rem !important;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

.ca-tab.tabbed-content.centered-tabs {
  mat-tab-header {
    padding-left: 0px !important;
  }
  .mat-mdc-tab-label {
    justify-content: center;
  }
}

/* Buttons, tab labels */
.mat-mdc-tab-label,
button {
  letter-spacing: 0.053rem;
  font-size: 0.875rem;
}

/* Labels in cards */
.entry-list .entry-list-item label {
  // font-size: 0.75rem;
  letter-spacing: 0.0333rem;
}

.mat-mdc-card .mat-card-header,
.entry-list .entry-list-item p {
  // font-size: 0.875rem;
  letter-spacing: 0.017857143rem;
}

.ca-capitalize-button {
  text-transform: capitalize !important;
}

.ca-user-menu .mat-mdc-menu-item span button {
  letter-spacing: 0;
}

mat-card-content.inner-content {
  padding-left: 0px !important;
}

.ca-form .mat-mdc-form-field {
  padding-top: 0px !important;
}

// Adds padding to tabs in modals
.ca-tab.padding-bottom {
  padding-bottom: resize(12) !important;
}

// Creates new line for \n
.ca-dialog-content {
  white-space: pre-line;
}

.success-snackbar {
  white-space: pre-wrap;
}

.custom-mat-card-container {
  min-width: 600px;
  max-width: 800px;
}
.custom-mat-card-container-small {
  min-width: 560px;
  max-width: 650px;
}
.custom-mat-card-container-large {
  min-width: 700px;
  max-width: 1000px;
}
.custom-mat-card-container-expanded {
  min-width: 700px;
}

.mt-0 {
  margin-top: 0px !important;
}
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-16 {
  margin-bottom: 0px !important;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  border-radius: 6px;
}
