.vis-background,
.vis-labelset,
.vis-timeline {
    overflow: hidden
}
.vis .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10
}


.vis [class*=span] {
    min-height: 0;
    width: auto
}
div.vis-configuration {
    position: relative;
    display: block;
    float: left;
    font-size: 12px
}
div.vis-configuration-wrapper {
    display: block;
    width: 700px
}
div.vis-configuration-wrapper::after {
    clear: both;
    content: "";
    display: block
}
div.vis-configuration.vis-config-option-container {
    display: block;
    width: 495px;
    border-width: 2px;
    border-style: solid;
    border-radius: 4px;
    margin-top: 20px;
    left: 10px;
    padding-left: 5px
}
div.vis-configuration.vis-config-button {
    display: block;
    width: 495px;
    height: 25px;
    vertical-align: middle;
    line-height: 25px;
    border-radius: 4px;
    border-width: 2px;
    border-style: solid;
    margin-top: 20px;
    left: 10px;
    padding-left: 5px;
    cursor: pointer;
    margin-bottom: 30px
}

div.vis-configuration.vis-config-button.hover {
    border-width: 2px;
    border-style: solid;
}

div.vis-configuration.vis-config-item {
    display: block;
    float: left;
    width: 495px;
    height: 25px;
    vertical-align: middle;
    line-height: 25px
}
div.vis-configuration.vis-config-item.vis-config-s2 {
    left: 10px;
    padding-left: 5px;
    border-radius: 3px
}
div.vis-configuration.vis-config-item.vis-config-s3 {
    left: 20px;
    padding-left: 5px;
    border-radius: 3px
}
div.vis-configuration.vis-config-item.vis-config-s4 {
    left: 30px;
    padding-left: 5px;
    border-radius: 3px
}
div.vis-configuration.vis-config-header {
    font-size: 18px;
    font-weight: 700
}
div.vis-configuration.vis-config-label {
    width: 120px;
    height: 25px;
    line-height: 25px
}
div.vis-configuration.vis-config-label.vis-config-s3 {
    width: 110px
}
div.vis-configuration.vis-config-label.vis-config-s4 {
    width: 100px
}
div.vis-configuration.vis-config-colorBlock {
    top: 1px;
    width: 30px;
    height: 19px;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    padding: 0;
    margin: 0;
    cursor: pointer
}
input.vis-configuration.vis-config-checkbox {
    left: -5px
}
input.vis-configuration.vis-config-rangeinput {
    position: relative;
    top: -5px;
    width: 60px;
    padding: 1px;
    margin: 0;
    pointer-events: none
}
.vis-panel,
.vis-timeline {
    padding: 0;
    box-sizing: border-box
}
input.vis-configuration.vis-config-range {
    -webkit-appearance: none;
    border-width: 0;
    width: 300px;
    height: 20px
}
input.vis-configuration.vis-config-range::-webkit-slider-runnable-track {
    width: 300px;
    height: 5px;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
}
input.vis-configuration.vis-config-range::-webkit-slider-thumb {
    -webkit-appearance: none;
    border-width: 1px;
    border-style: solid;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    margin-top: -7px
}
input.vis-configuration.vis-config-range:focus {
    outline: 0
}

input.vis-configuration.vis-config-range::-moz-range-track {
    width: 300px;
    height: 10px;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
}
input.vis-configuration.vis-config-range::-moz-range-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
}
input.vis-configuration.vis-config-range:-moz-focusring {
    outline-offset: -1px
}
input.vis-configuration.vis-config-range::-ms-track {
    width: 300px;
    height: 5px;
    background: 0 0;
    border-color: transparent;
    border-width: 6px 0;
    color: transparent
}
input.vis-configuration.vis-config-range::-ms-fill-lower {
    border-radius: 10px
}
input.vis-configuration.vis-config-range::-ms-fill-upper {
    border-radius: 10px
}
input.vis-configuration.vis-config-range::-ms-thumb {
    border: none;
    height: 16px;
    width: 16px;
    border-radius: 50%;
}

.vis-configuration-popup {
    position: absolute;
    border-width: 2px;
    border-style: solid;
    line-height: 30px;
    height: 30px;
    width: 150px;
    text-align: center;
    font-size: 14px;
    border-radius: 4px;
    -webkit-transition: opacity .3s ease-in-out;
    -moz-transition: opacity .3s ease-in-out;
    transition: opacity .3s ease-in-out
}
.vis-configuration-popup:after,
.vis-configuration-popup:before {
    left: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}
.vis-configuration-popup:after {
    border-width: 8px;
    margin-top: -8px
}
.vis-configuration-popup:before {
    border-width: 12px;
    margin-top: -12px
}
.vis-timeline {
    position: relative;
    border-width: 1px;
    border-style: solid;
    margin: 0
}
.vis-panel {
    position: absolute;
    margin: 0
}
.vis-panel.vis-bottom,
.vis-panel.vis-center,
.vis-panel.vis-left,
.vis-panel.vis-right,
.vis-panel.vis-top {
    border-width: 1px;
}
.vis-panel.vis-center,
.vis-panel.vis-left,
.vis-panel.vis-right {
    border-top-style: solid;
    border-bottom-style: solid;
    overflow: hidden
}
.vis-panel.vis-bottom,
.vis-panel.vis-center,
.vis-panel.vis-top {
    border-left-style: solid;
    border-right-style: solid
}
.vis-panel>.vis-content {
    position: relative
}
.vis-panel .vis-shadow {
    position: absolute;
    width: 100%;
    height: 1px;
}
.vis-itemset,
.vis-labelset,
.vis-labelset .vis-label {
    position: relative;
    box-sizing: border-box
}
.vis-panel .vis-shadow.vis-top {
    top: -1px;
    left: 0
}
.vis-panel .vis-shadow.vis-bottom {
    bottom: -1px;
    left: 0
}
.vis-labelset .vis-label {
    left: 0;
    top: 0;
    width: 100%;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}
.vis-labelset .vis-label.draggable {
    cursor: pointer
}
.vis-labelset .vis-label:last-child {
    border-bottom: none
}
.vis-labelset .vis-label .vis-inner {
    display: inline-block;
    padding: 5px
}
.vis-labelset .vis-label .vis-inner.vis-hidden {
    padding: 0
}
.vis-itemset {
    padding: 0;
    margin: 0
}
.vis-itemset .vis-background,
.vis-itemset .vis-foreground {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: visible
}
.vis-axis {
    position: absolute;
    width: 100%;
    height: 0;
    left: 0;
    z-index: 1
}
.vis-foreground .vis-group {
    position: relative;
    box-sizing: border-box;
    border-bottom-width: 1px;
    border-bottom-style: solid;
}
.vis-foreground .vis-group:last-child {
    border-bottom: none
}
.vis-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10
}
.vis-item {
    position: absolute;
    border-width: 1px;
    display: inline-block
}

.vis-item.vis-selected {
    z-index: 2
}
.vis-editable.vis-selected {
    cursor: move
}
.vis-item.vis-box {
    text-align: center;
    border-style: solid;
    border-radius: 2px
}
.vis-item.vis-point {
    background: 0 0
}
.vis-item.vis-dot {
    position: absolute;
    padding: 0;
    border-width: 4px;
    border-style: solid;
    border-radius: 4px
}
.vis-item.vis-range {
    border-style: solid;
    border-radius: 2px;
    box-sizing: border-box
}
.vis-item.vis-background {
    border: none;
    box-sizing: border-box;
    padding: 0;
    margin: 0
}
.vis-item .vis-item-overflow {
    position: relative;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    overflow: hidden
}
.vis-item .vis-delete,
.vis-item .vis-delete-rtl {
    background: url(../img/timeline/delete.png) center no-repeat;
    height: 24px;
    top: -4px;
    cursor: pointer
}
.vis-item.vis-range .vis-item-content {
    position: relative;
    display: inline-block
}
.vis-item.vis-background .vis-item-content {
    position: absolute;
    display: inline-block
}
.vis-item.vis-line {
    padding: 0;
    position: absolute;
    width: 0;
    border-left-width: 1px;
    border-left-style: solid
}
.vis-item .vis-item-content {
    white-space: nowrap;
    box-sizing: border-box;
    padding: 5px
}
.vis-item .vis-delete {
    position: absolute;
    width: 24px;
    right: -24px
}
.vis-item .vis-delete-rtl {
    position: absolute;
    width: 24px;
    left: -24px
}
.vis-item.vis-range .vis-drag-left {
    position: absolute;
    width: 24px;
    max-width: 20%;
    min-width: 2px;
    height: 100%;
    top: 0;
    left: -4px;
    cursor: w-resize
}
.vis-item.vis-range .vis-drag-right {
    position: absolute;
    width: 24px;
    max-width: 20%;
    min-width: 2px;
    height: 100%;
    top: 0;
    right: -4px;
    cursor: e-resize
}
.vis-range.vis-item.vis-readonly .vis-drag-left,
.vis-range.vis-item.vis-readonly .vis-drag-right {
    cursor: auto
}
.vis-time-axis {
    position: relative;
    overflow: hidden
}
.vis-time-axis.vis-foreground {
    top: 0;
    left: 0;
    width: 100%
}
.vis-time-axis.vis-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}
.vis-time-axis .vis-text {
    position: absolute;
    padding: 3px;
    overflow: hidden;
    box-sizing: border-box;
    white-space: nowrap
}
.vis-time-axis .vis-text.vis-measure {
    position: absolute;
    padding-left: 0;
    padding-right: 0;
    margin-left: 0;
    margin-right: 0;
    visibility: hidden
}
.vis-time-axis .vis-grid.vis-vertical {
    position: absolute;
    border-left: 1px solid
}
.vis-time-axis .vis-grid.vis-vertical-rtl {
    position: absolute;
    border-right: 1px solid
}

.vis-current-time {
    width: 2px;
    z-index: 1
}
.vis-custom-time {
    width: 2px;
    cursor: move;
    z-index: 1
}
div.vis-network div.vis-close,
div.vis-network div.vis-edit-mode div.vis-button,
div.vis-network div.vis-manipulation div.vis-button {
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none
}
.vis-panel.vis-background.vis-horizontal .vis-grid.vis-horizontal {
    position: absolute;
    width: 100%;
    height: 0;
    border-bottom: 1px solid
}

.vis-data-axis .vis-y-axis.vis-major {
    width: 100%;
    position: absolute;
    white-space: nowrap
}
.vis-data-axis .vis-y-axis.vis-major.vis-measure {
    padding: 0;
    margin: 0;
    border: 0;
    visibility: hidden;
    width: auto
}
.vis-data-axis .vis-y-axis.vis-minor {
    position: absolute;
    width: 100%;
    white-space: nowrap
}
.vis-data-axis .vis-y-axis.vis-minor.vis-measure {
    padding: 0;
    margin: 0;
    border: 0;
    visibility: hidden;
    width: auto
}
.vis-data-axis .vis-y-axis.vis-title {
    position: absolute;
    white-space: nowrap;
    bottom: 20px;
    text-align: center
}
.vis-data-axis .vis-y-axis.vis-title.vis-measure {
    padding: 0;
    margin: 0;
    visibility: hidden;
    width: auto
}
.vis-data-axis .vis-y-axis.vis-title.vis-left {
    bottom: 0;
    -webkit-transform-origin: left top;
    -moz-transform-origin: left top;
    -ms-transform-origin: left top;
    -o-transform-origin: left top;
    transform-origin: left bottom;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    transform: rotate(-90deg)
}
.vis-data-axis .vis-y-axis.vis-title.vis-right {
    bottom: 0;
    -webkit-transform-origin: right bottom;
    -moz-transform-origin: right bottom;
    -ms-transform-origin: right bottom;
    -o-transform-origin: right bottom;
    transform-origin: right bottom;
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg)
}
.vis-legend {
    padding: 5px;
    border-width: 1px;
    border-style: solid;
}
.vis-legend-text {
    white-space: nowrap;
    display: inline-block
}
.vis-graph-group0 {
    fill-opacity: 0;
    stroke-width: 2px;
}
.vis-graph-group1 {
    fill-opacity: 0;
    stroke-width: 2px;
}
.vis-graph-group2 {
    fill-opacity: 0;
    stroke-width: 2px;
}
.vis-graph-group3 {
    fill-opacity: 0;
    stroke-width: 2px;
}
.vis-graph-group4 {
    fill-opacity: 0;
    stroke-width: 2px;
}
.vis-graph-group5 {
    fill-opacity: 0;
    stroke-width: 2px;
}
.vis-graph-group6 {
    fill-opacity: 0;
    stroke-width: 2px;
}
.vis-graph-group7 {
    fill-opacity: 0;
    stroke-width: 2px;
}
.vis-graph-group8 {
    fill-opacity: 0;
    stroke-width: 2px;
}
.vis-graph-group9 {
    fill-opacity: 0;
    stroke-width: 2px;
}
.vis-timeline .vis-fill {
    fill-opacity: .1;
    stroke: none
}
.vis-timeline .vis-bar {
    fill-opacity: .5;
    stroke-width: 1px
}
.vis-timeline .vis-point {
    stroke-width: 2px;
    fill-opacity: 1
}
.vis-timeline .vis-legend-background {
    stroke-width: 1px;
    fill-opacity: .9;
}
.vis-timeline .vis-outline {
    stroke-width: 1px;
    fill-opacity: 1;
}
.vis-timeline .vis-icon-fill {
    fill-opacity: .3;
    stroke: none
}
div.vis-network div.vis-manipulation {
    border-width: 0;
    border-bottom: 1px;
    border-style: solid;
    padding-top: 4px;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 28px
}
div.vis-network div.vis-edit-mode {
    position: absolute;
    left: 0;
    top: 5px;
    height: 30px
}
div.vis-network div.vis-close {
    position: absolute;
    right: 0;
    top: 0;
    width: 30px;
    height: 30px;
    background-position: 20px 3px;
    background-repeat: no-repeat;
    background-image: url(../img/network/cross.png);
    user-select: none
}
div.vis-network div.vis-close:hover {
    opacity: .6
}
div.vis-network div.vis-edit-mode div.vis-button,
div.vis-network div.vis-manipulation div.vis-button {
    float: left;
    font-family: verdana;
    font-size: 12px;
    -moz-border-radius: 15px;
    border-radius: 15px;
    display: inline-block;
    background-position: 0 0;
    background-repeat: no-repeat;
    height: 24px;
    margin-left: 10px;
    padding: 0 8px;
    user-select: none
}

div.vis-network div.vis-manipulation div.vis-button.vis-back {
    background-image: url(../img/network/backIcon.png)
}
div.vis-network div.vis-manipulation div.vis-button.vis-none:hover {
    box-shadow: 1px 1px 8px transparent;
    cursor: default
}
div.vis-network div.vis-manipulation div.vis-button.vis-none:active {
    box-shadow: 1px 1px 8px transparent
}
div.vis-network div.vis-manipulation div.vis-button.vis-none {
    padding: 0
}
div.vis-network div.vis-manipulation div.notification {
    margin: 2px;
    font-weight: 700
}
div.vis-network div.vis-manipulation div.vis-button.vis-add {
    background-image: url(../img/network/addNodeIcon.png)
}
div.vis-network div.vis-edit-mode div.vis-button.vis-edit,
div.vis-network div.vis-manipulation div.vis-button.vis-edit {
    background-image: url(../img/network/editIcon.png)
}
div.vis-network div.vis-edit-mode div.vis-button.vis-edit.vis-edit-mode {
    border-width: 1px;
    border-style: solid;
}
div.vis-network div.vis-manipulation div.vis-button.vis-connect {
    background-image: url(../img/network/connectIcon.png)
}
div.vis-network div.vis-manipulation div.vis-button.vis-delete {
    background-image: url(../img/network/deleteIcon.png)
}
div.vis-network div.vis-edit-mode div.vis-label,
div.vis-network div.vis-manipulation div.vis-label {
    margin: 0 0 0 23px;
    line-height: 25px
}
div.vis-network div.vis-manipulation div.vis-separator-line {
    float: left;
    display: inline-block;
    width: 1px;
    height: 21px;
    margin: 0 7px 0 15px
}
div.vis-network-tooltip {
    position: absolute;
    visibility: hidden;
    padding: 5px;
    white-space: nowrap;
    font-family: verdana;
    font-size: 14px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    border-radius: 3px;
    border-width: 1px;
    border-style: solid;
    pointer-events: none
}
div.vis-network div.vis-navigation div.vis-button {
    width: 34px;
    height: 34px;
    -moz-border-radius: 17px;
    border-radius: 17px;
    position: absolute;
    display: inline-block;
    background-position: 2px 2px;
    background-repeat: no-repeat;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

div.vis-network div.vis-navigation div.vis-button.vis-up {
    background-image: url(../img/network/upArrow.png);
    bottom: 50px;
    left: 55px
}
div.vis-network div.vis-navigation div.vis-button.vis-down {
    background-image: url(../img/network/downArrow.png);
    bottom: 10px;
    left: 55px
}
div.vis-network div.vis-navigation div.vis-button.vis-left {
    background-image: url(../img/network/leftArrow.png);
    bottom: 10px;
    left: 15px
}
div.vis-network div.vis-navigation div.vis-button.vis-right {
    background-image: url(../img/network/rightArrow.png);
    bottom: 10px;
    left: 95px
}
div.vis-network div.vis-navigation div.vis-button.vis-zoomIn {
    background-image: url(../img/network/plus.png);
    bottom: 10px;
    right: 15px
}
div.vis-network div.vis-navigation div.vis-button.vis-zoomOut {
    background-image: url(../img/network/minus.png);
    bottom: 10px;
    right: 55px
}
div.vis-network div.vis-navigation div.vis-button.vis-zoomExtends {
    background-image: url(../img/network/zoomExtends.png);
    bottom: 50px;
    right: 15px
}
div.vis-color-picker {
    position: absolute;
    top: 0;
    left: 30px;
    margin-top: -140px;
    margin-left: 30px;
    width: 310px;
    height: 444px;
    z-index: 1;
    padding: 10px;
    border-radius: 15px;
    display: none;
}
div.vis-color-picker div.vis-arrow {
    position: absolute;
    top: 147px;
    left: 5px
}
div.vis-color-picker div.vis-arrow::after,
div.vis-color-picker div.vis-arrow::before {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}
div.vis-color-picker div.vis-arrow:after {
    border-width: 30px;
    margin-top: -30px
}
div.vis-color-picker div.vis-color {
    position: absolute;
    width: 289px;
    height: 289px;
    cursor: pointer
}
div.vis-color-picker div.vis-brightness {
    position: absolute;
    top: 313px
}
div.vis-color-picker div.vis-opacity {
    position: absolute;
    top: 350px
}
div.vis-color-picker div.vis-selector {
    position: absolute;
    top: 137px;
    left: 137px;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    border-width: 1px;
    border-style: solid;
}
div.vis-color-picker div.vis-initial-color,
div.vis-color-picker div.vis-new-color {
    width: 140px;
    height: 20px;
    top: 380px;
    font-size: 10px;
    line-height: 20px;
    position: absolute;
    vertical-align: middle
}
div.vis-color-picker div.vis-new-color {
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    left: 159px;
    text-align: right;
    padding-right: 2px
}
div.vis-color-picker div.vis-initial-color {
    border-width: 1px;
    border-style: solid;
    border-radius: 5px;
    left: 10px;
    text-align: left;
    padding-left: 2px
}
div.vis-color-picker div.vis-label {
    position: absolute;
    width: 300px;
    left: 10px
}
div.vis-color-picker div.vis-label.vis-brightness {
    top: 300px
}
div.vis-color-picker div.vis-label.vis-opacity {
    top: 338px
}
div.vis-color-picker div.vis-button {
    position: absolute;
    width: 68px;
    height: 25px;
    border-radius: 10px;
    vertical-align: middle;
    text-align: center;
    line-height: 25px;
    top: 410px;
    border-width: 2px;
    border-style: solid;
    cursor: pointer
}
div.vis-color-picker div.vis-button.vis-cancel {
    left: 5px
}
div.vis-color-picker div.vis-button.vis-load {
    left: 82px
}
div.vis-color-picker div.vis-button.vis-apply {
    left: 159px
}
div.vis-color-picker div.vis-button.vis-save {
    left: 236px
}
div.vis-color-picker input.vis-range {
    width: 290px;
    height: 20px
}