.app-grid-with-pager {
  box-sizing: border-box;
  max-width: 100% !important;
}

.app-grid-with-filter-pager-grand-total {
  height: calc(100% - 119px) !important;
}
.app-grid-with-filter-pager-grand-total-horizontal-scroll {
  height: calc(100% - 123px) !important;

  .dx-freespace-row {
    display: block !important;
    height: 13px !important;
    width: 100% !important;
  }
}

.dx-datagrid-rowsview
  .dx-select-checkboxes-hidden
  > tbody
  > tr
  > td
  > .dx-select-checkbox {
  display: inline-block !important;
}

.dx-datagrid-rowsview .dx-row.dx-group-row,
.dx-datagrid-summary-item {
  font-weight: normal !important;
}

.grouped-grid {
  table tr td:last-child {
    padding-right: 7px !important;
  }
}

.tabbed-grid {
  .mat-tab-body.mat-tab-body-active {
    height: 100%;
  }
}

.two-lines-cell {
  padding: 0;
  margin: -6px 0 -6px 0;
}

.two-lines-cell-wrapper {
  top: -7px;
}

.two-lines-cell .title {
  overflow: hidden;
  text-overflow: ellipsis;
}

.two-lines-cell .subtitle {
  line-height: 12px;
  opacity: 0.6;
  font-size: 0.7em;
}

span.dx-datagrid-nodata {
  position: sticky !important;
}

span.dx-datagrid-nodata-fixed {
  span.dx-datagrid-nodata {
    position: fixed !important;
    top: 55%;
  }
}

.dx-nodata-absolute {
  span.dx-datagrid-nodata {
    position: absolute !important;
  }
}

.dx-nodata-fixed {
  span.dx-datagrid-nodata {
    position: fixed !important;
    top: 58% !important;
    left: 58% !important;
  }
}

.app-grid-with-filter-pager-grand-total {
  height: calc(100% - 119px) !important;
}

.app-wireless-grand-total {
  height: calc(100% - 60px) !important;
}
.app-grid-with-filter-pager-grand-total-horizontal-scroll {
  height: calc(100% - 123px) !important;
}

.dx-datagrid-content-fixed {
  .dx-freespace-row {
    margin-top: 100px;
  }
}

.grid-settings-fields {
  max-width: 160px !important;
  min-width: 105px !important;
}
