@function resizeCard($value, $add: 0) {
  @return ($value + $add) * $card-multiplier * 1px;
}

[caCard] {
  padding-bottom: 8px !important;
  margin: 12px !important;
  mat-card-header {
    font-size: $font_size_body1;
    font-weight: 500;
    padding-top: 0 !important;
  }

  .entry-list {
    margin-bottom: resizeCard(10) !important;

    &:last-of-type {
      margin-bottom: resizeCard(4) !important;
    }
  }

  .entry-list-item {
    margin-right: resizeCard(4);
    padding: resizeCard(0) resizeCard(12) !important;
    font-size: $font_size_body2;
    &:last-of-type {
      margin-right: 0;
    }

    label {
      font-size: $font_size_caption;
      opacity: 0.6 !important;
      word-break: break-word !important;
    }

    p {
      line-height: resizeCard(20);
      margin: 0;
      word-break: break-word !important;
      opacity: 0.87 !important;
    }
  }
}


.invoice-summary-card {
  .card-list-entry, .card-list-entry-bgr {
    font-size: $font_size_body2 !important;
  }
}

.mat-mdc-select-panel .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.settings-form .mat-mdc-form-field-subscript-wrapper {
  display: none;
}

.settings-form .mat-mdc-select-arrow-wrapper {
  width: 24px;
}