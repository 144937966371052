app-theme-manage {
  .mat-tab-body-content {
    overflow-x: hidden;
  }
}

.theme-list {
  margin-top: 15px;
  display: block !important;
  margin-right: 20px;
  border-right: 1px solid;
  min-width: 135px;
  max-width: 135px;
  .theme {
    display: inline;

    .entry-container {
      display: inline-block;
      padding: 5px 20px 5px 25px;
      cursor: pointer;
      transition: background 0.3s ease;
    }

    span {
      display: inline-block;
      width: 30px;
      height: 30px;
    }

    label {
      display: block;
      text-align: left;
      line-height: 12px;
      font-size: 11px;
      margin-bottom: 2px;
    }
  }

  .theme.selected {
    position: relative;
  }
}
.mat-input-container.color-input:first-child {
  margin-right: 15px;
}
.mat-input-container.color-input {
  .color-picker {
    .hex-text,
    .button-area {
      display: none !important;
    }
    .box {
      margin-top: -10px;
      padding: 4px 0 !important;

      .left {
        display: none;
      }
      .right {
        padding: 0 !important;
        .hue {
          margin-bottom: 0 !important;
          border-radius: 2px;
        }

        .alpha {
          display: none;
        }
      }
    }
    .type-policy {
      display: none;
    }
  }
}

.color-picker {
  padding: 9px !important;
  border-radius: 4px !important;
}

.color-picker .saturation-lightness {
  border-radius: 4px;
}

.color-grid {
  margin-top: 30px;
}

.margin-right-minus-4 {
  margin-right: -4px;
}

.rounded-corners-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.rounded-corners-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.ca-theme-manage-form {
  mat-tab-header {
    margin-top: 10px;
  }
  mat-form-field {
    padding-top: 0.5em !important;
  }

  .theme_middle_column {
    border-left: 1px solid;
    border-right: 1px solid;
    padding-left: 20px;
    padding-right: 20px;
    margin-right: 20px;
    margin-left: 20px;
  }
  .theme-chart-left-side {
    border-right: 1px solid;
    padding-right: 20px;
    margin-right: 20px;
  }

  .share-theme-container {
    width: 100%;
    margin-top: 20px;
  }
}
