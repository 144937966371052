$font-family: "Roboto", "Helvetica Neue", sans-serif;
$font-size: 14px;

$ca-form-flex-item-spacing: 15px;
$ca-form-font-family: $font-family;
$ca-form-font-size: 14px;

$ca-filter-icon-size: 16px;

$ca-menu-typography: 14;

$multiplier: 1;
$card-multiplier: 1;
$hi-dens-multiplier: 1;
$pager-multiplier: 1;
$table-multiplier: 1;
$nav-multiplier: 1;
$sidenav-multiplier: 1;
$bar-multiplier: 1;
$tab-multiplier: 1;
$flow-multiplier: 1;

// https://material.io/design/typography/the-type-system.html#type-scale

// MD variables, used by default
$font_size_md_h1: 96px;
$font_size_md_h2: 68px;
$font_size_md_h3: 48px;
$font_size_md_h4: 34px;
$font_size_md_h5: 24px;
$font_size_md_h6: 20px;
$font_size_md_subtitle: 16px;
$font_size_md_subtitle2: 14px;
$font_size_md_body1: 16px;
$font_size_md_body2: 14px;
$font_size_md_button: 14px;
$font_size_md_caption: 12px;
$font_size_md_overline: 10px;
$ca_gap_md: 24px;

// Small variables
$font_size_sm_h1: 94px;
$font_size_sm_h2: 66px;
$font_size_sm_h3: 46px;
$font_size_sm_h4: 32px;
$font_size_sm_h5: 22px;
$font_size_sm_h6: 18px;
$font_size_sm_subtitle: 14px;
$font_size_sm_subtitle2: 12px;
$font_size_sm_body1: 14px;
$font_size_sm_body2: 12px;
$font_size_sm_button: 12px;
$font_size_sm_caption: 10px;
$font_size_sm_overline: 8px;
$ca_gap_sm: 16px;

// general variables used  for referencing values
$font_size_h1: $font_size_md_h1;
$font_size_h2: $font_size_md_h2;
$font_size_h3: $font_size_md_h3;
$font_size_h4: $font_size_md_h4;
$font_size_h5: $font_size_md_h5;
$font_size_h6: $font_size_md_h6;
$font_size_subtitle: $font_size_md_subtitle;
$font_size_subtitle2: $font_size_md_subtitle2;
$font_size_body1: $font_size_md_body1;
$font_size_body2: $font_size_md_body2;
$font_size_button: $font_size_md_button;
$font_size_caption: $font_size_md_caption;
$font_size_overline: $font_size_md_overline;
$ca_gap: $ca_gap_md;

@function resize($value, $add: 0) {
  @return ($value + $add) * $multiplier * 1px;
}

@function resizeHiDens($value, $add: 0) {
  @return ($value + $add) * $hi-dens-multiplier * 1px;
}

@function resizeTable($value, $add: 0) {
  @return ($value + $add) * $table-multiplier * 1px;
}
