@import "variables";

html,
body {
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

body {
  overflow-y: hidden;
}

button {
  font-family: $font-family !important;
}

.body-login {
  background-size: 100% 100%;
}

@media screen and (max-width: 640px) {
  /* Specific to this particular image */
  .body-login {
    background: none;
  }

  .sign-in-form {
    margin-right: 25px !important;
  }
}

@media screen and (max-height: 480px) {
  /* Specific to this particular image */
  .body-login {
    background: none;
  }
}

app-dash,
app-root {
  height: 100%;
}

/*
  Main Content
*/

.app-content-list.app-content-list-no-pager {
  .dx-datagrid-rowsview {
    padding-bottom: 0 !important;
  }
}

.app-content-list-with-filter {
  .dx-datagrid-rowsview {
    padding-bottom: 137px !important;
  }
}

.app-content-gallery {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
}

/*
  dictionary special case remove margin-bottom on list
*/
.no-padding-app-content-list {
  overflow: hidden;

  .mat-sidenav-content,
  .mat-sidenav-content {
    overflow: hidden !important;
  }

  .dx-datagrid-rowsview {
    padding-bottom: 0 !important;
  }
}

/*
  add padding to list to account for pager
*/

/*
  documents grid toolbar positioning + contracts details key sections ( for consistency uses same styles as documents grid )
*/
.ca-documents-container,
.ca-key-sections-container {
  position: relative;
}

.ca-documents-container app-document-manager .mat-toolbar,
.ca-key-sections-container .mat-toolbar {
  position: absolute;
  top: 0;
  right: 8px;

  width: auto;
}

.ca-documents-container app-document-manager .mat-toolbar .mat-toolbar-row,
.ca-key-sections-container .mat-toolbar .mat-toolbar-layout .mat-toolbar-row {
  width: auto;
  padding-bottom: 12px;
  padding-top: 8px;
}

.ca-documents-container .mat-card-header,
.ca-key-sections-container .mat-card-header {
  margin-bottom: 16px !important;
}

/* form > div.flex-container:first-of-type {
  padding-top: 0.5em
}
 */
/* Report gallery pivot table adjustment */

.pivot-scroll {
  .mat-drawer-content {
    overflow: scroll !important;
  }
}

app-report-default {
  width: 100%;
  height: 100%;
  display: block;
}

.ca-pivot-table .dx-area-row-cell {
  padding-left: 0 !important;
}

.ca-pivot-table .dx-area-data-cell {
  padding-right: 0 !important;
}

/* Dictionary grid hide header */
.ca-dictionary-grid .dx-datagrid-headers {
  display: none;
}

/* SRM grid hide header */
.ca-srm-grid {
  .dx-datagrid-headers {
    display: none;
  }

  max-width: 100% !important;
}

.custom-accorion-list-item {
  cursor: pointer;
  padding: 2px;
  font-size: $font_size_body2 !important;

  height: 30px !important;

  .mat-list-text {
    span {
      font-size: $font_size_body2;
    }
  }

  div {
    height: 30px !important;
  }
}

.srm-pivot-settings {
  .panel-body {
    padding: 0;
  }
}

.srm-menu-settings {
  .mat-mdc-menu-content {
    width: 200px;
    padding: 15px;
  }
}

/* set default font-family*/
* {
  font-family: $font-family;
}

.mat-mdc-tab-group,
.mat-tooltip,
.mat-slide-toggle-content,
.mat-dialog-title,
.mat-tab-label,
.mat-tab-link,
text {
  font-family: $font-family !important;
}

/* Inner content padding for mat-card-content */
mat-card-content.inner-content {
  padding-left: 30px;
}

.filter-checkbox {
  //margin: 8px 0;
  display: block;
}

.filter-checkbox .mat-checkbox-layout {
  align-items: center;
}

.filter-checkbox .mat-checkbox-layout .mat-checkbox-label {
  line-height: normal;
  white-space: normal;
}

.mat-checkbox label.mat-checkbox-layout {
  margin-bottom: resize(12) !important;
}

.no-bottom-padding {
  padding-bottom: 0px;
}

.clear-option {
  text-align: left !important;
  width: 100%;
  font-style: italic;
  opacity: 0.5;
}

/* Theme modal input adjustments */
.ca-theme-manage-form .mat-input-wrapper {
  margin-bottom: 0 !important;
}

/* Contracts list style adjustments for slide in grid */
.contracts-grid-container mat-tab-header {
  height: 0 !important;
}

.ca-contracts-tab-group {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.ca-contracts-tab-group .mat-tab-body-wrapper {
  height: 100%;
}

.ca-sidepanel-tab-group .mat-tab-body-wrapper {
  position: absolute !important;
  top: 48px;
  left: 0;
  right: 0;
  bottom: 0;
}

.ca-contracts-tab-group
  .dx-datagrid.dx-datagrid-borders
  > .dx-datagrid-headers {
  border-top: none;
}

/* Manage theme color picker styles */
.color-picker {
  position: absolute !important;
  top: -45px !important;
  left: 61px !important;
}

.color-picker-right .color-picker {
  position: absolute !important;
  top: -45px !important;
  left: -275px !important;
}

.color-picker-trigger {
  text-transform: uppercase !important;
}

/* Highlight first row on contract document if it's main contract document */
.has-contract-document {
  font-weight: bold !important;
}

/* picker search and pagination */
.ca-picker-search-container .mat-input-wrapper {
  margin: 0;
}

.ca-picker-search-container .mat-input-underline .mat-input-ripple.mat-focused {
  transform: scaleY(0);
}

.ps-container > .ps-scrollbar-y-rail {
  width: 10px !important;
}

.ps-container.ps-in-scrolling.ps-y > .ps-scrollbar-y-rail > .ps-scrollbar-y {
  width: 6px !important;
}

.ps-container > .ps-scrollbar-y-rail:hover > .ps-scrollbar-y,
.ps-container > .ps-scrollbar-y-rail:active > .ps-scrollbar-y {
  width: 6px !important;
}

// __<<ngThemingMigrationEscapedComment17>>__
.vis-item .vis-item-overflow {
  overflow: visible;
}

.ca-picker-search-container .mat-input-wrapper {
  padding-bottom: 0 !important;
}

.ca-picker-search-container.mat-focused .mat-input-ripple {
  visibility: hidden !important;
}

.font-size-13 {
  font-size: 13px !important;
}

.ca-charge-datepicker-input .mat-input-wrapper {
  padding-bottom: 1em;
}

.srm-tooltip {
  font-size: 16px !important;
  max-width: 230px !important;
}

.font-size-xxl {
  font-size: 16px !important;
}

.customer-inventory-docs-manager dx-data-grid {
  margin-top: -1px;
}

.mat-snack-bar-container {
  margin-bottom: 24px;
}

.charge-grid-icons {
  cursor: pointer;
  position: absolute;
  bottom: 0px;
  top: -2px;

  &.note-icon {
    top: -8px !important;
  }
}

.charge-grid-icon {
  height: 24px;
  width: 24px;
}

.pending-disconnect-icon {
  height: 16px !important;
  width: 16px !important;
  font-size: 16px;
}

/* contract vendor document manager grid styles */
.contract-vendor-info-doc-manager .grid-body {
  height: 100%;
}

/* Trying out a fix for picker expanding after something is selected */
span.mat-select-placeholder.mat-floating-placeholder {
  width: auto !important;
}

.quote-wizard-select-field {
  .mat-select {
    padding-top: 17px !important;
  }
}

.ca-link-icon {
  line-height: 0.9;

  cursor: pointer;
}

.ca-field-with-icon {
  position: relative;
  top: -3px;
}

.grid-settings-caption {
  text-transform: uppercase;
}

.grid-settings-caption-capitalize {
  text-transform: capitalize;
}

.group-by-caption-wrapper {
  .grid-settings-caption {
    padding-left: 0 !important;
  }
}

/***
*
*  In notes component there is too much space between note text input and "Line Item" select
*  this is why we're deviating from our standard and removing the padding
*
***/
.ca-form .mat-mdc-form-field.ca-note-line-item-form-field,
.ca-form .mat-mdc-form-field {
  padding-top: 0 !important;
}

/***
*
* For contact function item list min-height had to be unset since it fcked with the layout
*
***/
.ca-form .flex-container .flex-item.contact-function-list-item {
  min-height: unset;
}

/* TREE VIEW STYLE ADJUSTMENTS */
app-network-hub-manage-dialog app-network-hub-collapsible-tree {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

app-network-hub-manage-dialog
  app-network-hub-collapsible-tree
  .tree-view-container {
  margin-left: 0 !important;
}

app-network-hub-manage-dialog
  app-network-hub-collapsible-tree
  .ca-tree-clear-selection {
  display: none !important;
}

app-network-hub-manage-dialog app-network-hub-collapsible-tree .ca-tree-node {
  height: 125px !important;
}

app-network-hub-manage-dialog
  app-network-hub-collapsible-tree
  .slot-item-card:hover
  .ca-tree-clear-selection {
  display: block !important;
}

app-network-hub-details .slot-item-card .ca-tree-clear-selection {
  display: none !important;
}

app-network-hub-manage-dialog
  app-network-hub-collapsible-tree
  .available-hub-slot:hover
  .ca-tree-clear-selection {
  display: none !important;
}

app-network-hub-details
  app-network-hub-collapsible-tree
  .dx-treeview
  .dx-treeview-node:not(.dx-treeview-item-with-checkbox).dx-state-focused
  > .dx-treeview-item,
.dx-treeview-node .dx-state-selected > .dx-treeview-item {
  background-color: transparent !important;
}

app-network-hub-manage-dialog
  app-network-hub-collapsible-tree
  .dx-treeview
  .dx-treeview-item {
  padding: 3px;
}

app-network-hub-details app-network-hub-collapsible-tree .ca-tree-mat-card {
  min-width: 350px;
  max-width: 350px;
}

app-network-hub-details
  app-network-hub-collapsible-tree
  .ca-tree-ethernet-speed-node {
  vertical-align: middle !important;
}

app-network-hub-manage-dialog
  app-network-hub-collapsible-tree
  .ca-tree-link-chevron {
  display: none !important;
}

app-network-hub-details
  app-network-hub-collapsible-tree
  .slot-item-card
  .ca-tree-link-chevron {
  display: none !important;
}

app-network-hub-details
  app-network-hub-collapsible-tree
  .slot-item-card:hover
  .ca-tree-link-chevron {
  display: block !important;
}

/* Center document manager grid cells vertically on order details page */
app-order-details
  app-document-manager
  .dx-datagrid-content
  .dx-datagrid-table
  .dx-row
  > td {
  vertical-align: middle;
}

/* Autocomplete dropdown to not go above the input */
.ng2-dropdown-menu {
  position: fixed !important;
  max-height: calc(5 * 2em) !important;
  padding: 0 0 0.5em !important;
  padding-bottom: 0 !important;
}

.ng2-menu-item {
  height: 3em !important;
  line-height: 3em !important;
  font-size: 12px !important;
  font-family: "Roboto", "Helvetica Neue", sans-serif !important;
  padding: 0 1em !important;
}

.mat-chip:not(.mat-basic-chip) {
  background-color: #e0e0e0 !important;
  color: rgba(0, 0, 0, 0.87) !important;
}

/* MAT ACCORDION STYLE ADJUSTMENTS */
mat-accordion .mat-mdc-list .mat-mdc-list-item .mdc-list-item__content {
  padding: 0 22px;
  width: 100%;
}

.mdc-list-item__content {
  width: 100%;
}

.notes-view .note-card {
  background-color: transparent;
  box-shadow: none !important;
}

.ca-srm-data-accordion-container .mat-expansion-panel-body {
  padding-bottom: 0 !important;
}

.ca-srm-pivot-settings-accordion-container .mat-expansion-panel-body,
.notes-view .mat-expansion-panel-body {
  padding: 0;
}

/* srm pivot options show button on hover */
.srm-pivot-button {
  display: none !important;
}

.srm-list-group-item:hover .srm-pivot-show-button-on-hover,
.custom-column-list-item:hover .srm-pivot-show-button-on-hover,
.list-group-item:hover .srm-pivot-show-button-on-hover,
.data-row:hover .srm-grid-show-button-on-hover {
  display: inline-block !important;
}

/* Settings modal - Reset tab text style */
.reset-settings-text,
.reset-settings-text .reset-settings-checkbox-text {
  font-size: 16px;

  .reset-settings-text-second-line {
    margin-bottom: 32px;
  }
}

image-cropper#file-source-cropper,
image-cropper#file-source-cropper div:first-child,
image-cropper#file-source img.source-image,
image-cropper#file-source > div > img.source-image,
image-cropper#file-source > img.source-image,
image-cropper#file-source > div > img.source-image {
  width: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
}

.tooltip-break-line {
  white-space: pre-line;
}

.ca-form .mat-mdc-form-field.ca-srm-table-settings-sort-select {
  padding-top: 0 !important;

  .mat-mdc-text-field-wrapper {
    margin-bottom: -1.25em !important;
  }

  .mat-mdc-form-field-infix {
    border-top: 0;
  }
}

.ca-customer-equipment-transaction-picker {
  max-height: unset !important;
}

@mixin caCard {
}

@include caCard;

.ca-card {
  @include caCard;
}

/*.flex-container .flex-item:last-child:not(:first-child),
.flex-container .flex-item-multiple:not(:first-child),
.flex-container .flex-item-multiple-even:nth-child(even),
.flex-container-2 .flex-item:nth-child(even),
.flex-container-3 .flex-item:not(:first-child) {
    padding-left: resize(18);
}*/
.no-left-padding {
  padding-left: 0 !important;
}

.mat-mdc-text-field-wrapper {
  padding-bottom: 0 !important;

  .mat-form-field-underline {
    position: static;
  }
}

.srm-interactive-dropdown {
  .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
  }
}

.ca-form .mat-mdc-form-field,
.mat-mdc-text-field-wrapper {
  padding-top: 0 !important;
}

.mat-checkbox {
  label.mat-checkbox-layout {
    /* padding-top: resize(9); */
    padding-bottom: 0 !important;
    margin-bottom: 24px;
  }
}

// .mat-mdc-select-panel {
//   width: fit-content !important;
// }

new-filter-input {
  overflow-y: auto;
  padding: 2px;

  .mat-mdc-form-field {
    &.mat-primary {
      background: unset !important;
    }
  }
}

app-filter-container {
  mat-accordion {
    overflow-y: auto;
    padding: 2px;

    .mat-mdc-form-field {
      &.mat-primary {
        background: unset !important;
      }
    }
  }
}

.filters-menu {
  max-height: 300px !important;

  .mat-option {
    margin: 0;
    width: 100%;
  }
}

.mat-slide-toggle-thumb-container {
  pointer-events: none;
}

.cdk-overlay-pane {
  height: auto !important;
  //max-height: 90% !important;
  //overflow: auto !important;
}

.cdk-overlay-pane {
  height: auto !important;
}

.address-filter {
  .ca-form {
    min-height: unset;
  }
}

.text-filter {
  [hiDensityInput] {
    .mat-mdc-form-field-infix {
      margin-right: resizeHiDens(12);
      align-items: center !important;
    }

    input {
      margin-top: unset !important;
    }

    ::ng-deep .input-command ca-icon .material-icons {
      font-size: $font_size_body2 !important;
      height: unset;
      width: unset;
    }

    ::ng-deep ca-icon mat-icon.mat-icon.material-icons {
      height: unset !important;
      width: unset !important;
      font-size: $font_size_body2 !important;
    }
  }
}

.cdk-overlay-pane {
  height: auto !important;
}

.group-by-buttons-width-override {
  button {
    width: 25px !important;
    height: 100% !important;
  }
}

mat-datepicker-content {
  .mat-calendar-table-header {
    th {
      font-size: resize(14);
    }
  }

  .mat-calendar-body-cell {
    font-size: resize(16);
  }
}

div.mat-mdc-autocomplete-panel {
  padding: 0 !important;
}
