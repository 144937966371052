.ca-table {
  width: 100%;
  border-spacing: 0;
  height: 100%;

  td, th {
    padding: 10px;
    text-align: left;
  }

  td.col-command {
    width: 100px;
    text-align: left;
  }

  td.col-checkbox,
  th.col-checkbox {
    width: 30px;
    text-align: center;
  }

  &.ca-table-stripped {
    td {
      border-bottom: 1px solid;
    }
  }

  td.select-box,
  th.select-box {
    min-width: 200px;
  }
}

.ca-table-cozy {
  tr td:first-child,
  tr th:first-child,
  tr td:last-child,
  th td:last-child {
    padding: 0 !important;
  }
  tr td:nth-child(2),
  tr th:nth-child(2) {
    padding-right: 12px !important;
  }

  tr td,
  tr th {
    padding: 0 !important;
  }

  mat-form-field {
    padding-top: 0 !important;
  }
}

.ca-form td .mat-checkbox.ca-checkbox-no-padding label.mat-checkbox-layout {
  padding: 0 !important;
}

td.td-picker-padding {
  padding-top: resize(8) !important;
  padding-bottom: 0 !important;
  .mat-mdc-text-field-wrapper {
    padding-bottom: resize(8) !important;
  }
  mat-form-field.td-picker-no-padding {
      padding: 0 !important;
  }
}
th .mat-checkbox label.mat-checkbox-layout {
  padding-bottom: 0;
}
