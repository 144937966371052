@function resizeSidenav($value, $add: 0) {
  @return ($value + $add) * $sidenav-multiplier * 1px;
}

.note-card-date {
  font-size: 12px;
}

.history .history-item {
  font-size: $font_size_body2;
  letter-spacing: 0.017857143rem;
}
mat-sidenav {
  width: resizeSidenav(330);
  border-top-width: 1px;
  border-top-style: solid;
  border-left-width: 1px;
  border-left-style: solid;
  overflow-x: hidden;

  &.srm {
    border-width: 1px;
    border-style: solid;
  }

  .ca-full-width {
    width: 100%;
  }

  .mat-content {
    align-items: center !important;
  }

  .filters-accordion-container {
    .actions-buttons {
      text-align: left;
    }

    ::ng-deep input.mat-chip-input{
      color: black;
    }

    ::ng-deep mat-chip{
      margin-bottom: 5px;
    }

    mat-form-field {
      padding: 0 !important;
    }

    .ca-form{
      margin-bottom: 0 !important;
    }

    ::ng-deep .mat-mdc-form-field-infix {
      border: 0 !important;
    }

    ca-tag-filter-input {
      ::ng-deep .mat-mdc-form-field-infix {
        padding: 0 !important;
        border: 0 !important;
      }

      ::ng-deep .mat-input-container {
        padding: 0 !important;
      }
    }

    ::ng-deep .mat-checkbox-layout {
      padding-top: 0 !important;
    }

    ::ng-deep .input-command {
      .material-icons {
        font-size: resizeSidenav(15) !important;
      }
    }

    .mat-expansion-panel-body {
      padding-left: resizeSidenav(15) !important;
      padding-right: resizeSidenav(15) !important;

      [hiDensityInput] {
        .mat-mdc-text-field-wrapper {
          padding-bottom: resizeSidenav(0) !important;
        }
      }

      multi-select-filter-input {

        .ca-form {
          min-height: auto !important;
        }
      }

      .mat-checkbox {
        label.mat-checkbox-layout {
          padding-bottom: 0 !important;
          margin-bottom: resizeSidenav(16);
        }
      }
    }

    mat-expansion-panel-header {
      // padding: resizeSidenav(16) !important;
      // height: resizeSidenav(15) !important;
      font-size: $font_size_body1 !important;
    }

    .ca-expansion-panel-header-title {
      // height: resizeSidenav(54) !important;
      font-size: $font_size_body1 !important;
      font-weight: 500 !important;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }

  ::ng-deep .mat-expansion-panel-body {
    padding: 0 24px 0px !important;
  }

  .mat-expansion-panel-spacing {
    margin: 8px 0 !important;
  }

  .dnd-sortable-drag {
    opacity: 0.2;
    visibility: visible;
    margin: resizeSidenav(10) 0;
  }

  .filter-remove-blur-area{
    background: red;
  }


  ::ng-deep .mat-option-text {
    max-width: 35ch !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .filters-title {
    margin-left: 2px;
    line-height: 20px;
    font-weight: 500;
    font-size: resizeSidenav(20);
  }

  .filter-remove-area {
    width: 100%;
    height: 45px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;

    ca-icon {
      display: flex;
      flex: 1 1 100%;
      align-items: center;
      justify-content: center;
      height: 100%;
      margin: 2px 5px;
    }
  }

  .filter-remove-area-bg {
    width: 100%;
    height: 45px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  .empty-filter-input {
    width: 100%;
    top: 50%;
    position: absolute;

    .message-holder {
      width: 100%;
      height: 100%;
      align-items: center;
      display: flex;
    }

    p {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .mat-toolbar.mat-toolbar-single-row {
    height: resizeSidenav(48) !important;

    .mdc-icon-button {
      width: resizeSidenav(40);
      height: resizeSidenav(40);
      line-height: resizeSidenav(40);

      .material-icons {
        font-size: resizeSidenav(24);
      }
    }
  }
}

mat-sidenav mat-card {
  margin-bottom: 5px;
}

mat-sidenav mat-toolbar {
  padding: 0 !important;
  position: sticky;
  top: 0;
  z-index: 1;
}

mat-sidenav mat-toolbar .sneak-peek-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 8px;
  font-weight: 500;
  line-height: resizeSidenav(20);
  font-size: $font_size_h6;
}

mat-sidenav mat-card-content {
  font-size: 13px;
  padding: 0 !important;
}

.clone {
  min-width: resizeSidenav(250);
  opacity: 1 !important;
  background: white;
  border: 2px solid gray;
  visibility: visible !important;
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -1;

  ::ng-deep .mat-expansion-indicator {
    visibility: hidden !important;
  }
}

