@import "../variables";

::ng-deep .mat-mdc-list .mat-mdc-list-item .mat-line.notification-entry-title {
  /*overflow: hidden !important;
  text-overflow: ellipsis !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  line-height: 20px !important;
  white-space: normal;
  height: 40px !important;*/
  height: auto;
  white-space: pre-line !important;
  padding-top: 4px;
}


.mat-mdc-list-item.notification-entry {
  padding: 16px 0;
  height: auto !important; 
}

.mat-mdc-list-item:hover {
  background: rgba(0, 0, 0, 0.04);
}

.notification-list.mat-mdc-list {
  padding-top: 0 !important;
}

app-notification .badge-danger {
  opacity: 1 !important;
  height: 4px;
}

.notification-entry-commands .mdc-icon-button {
    height: 25px !important;
    width: 25px !important;
    line-height: 20px !important;
    margin: 0 !important;
    padding: 0;
  }

.notification-entry-commands .material-icons {
  font-size: 20px !important;
}

.notification-entry-date {
  align-items: center;
}

.mdc-list-item__content {
  padding: 0 16px;
}

