.avatar {
  font-weight: 700;
  margin: 2px;
}

.avatar img,
.avatar .replacement {
  width: 48px;
  height: 48px
}

.avatar.avatar-md img,
.avatar.avatar-md .replacement {
  width: 40px;
  height: 40px
}

.avatar.avatar-xs img,
.avatar.avatar-xs .replacement {
  width: 24px;
  height: 24px
}

.avatar-link {
  cursor: pointer;
}
