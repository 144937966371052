//@import '~@angular/material/core/theming/theming';
@import '@angular/material/_theming';
@import "../../assets/themes/default";

@function resizeNav($value, $add: 0) {
  @return ($value + $add) * $nav-multiplier * 1px;
}

.navigation-container-main {
  position: absolute !important;
  top: resizeNav(64);
  left: 0;
  right: 0;
  bottom: 0;
}

.site-navigation {
  background-color: mat-color($app-primary, 500) !important;
  overflow-x: hidden;
  min-width: resizeNav(40) !important;
  transition: width 0.25s;
  -webkit-transition: width .25s;
  -moz-transition: width 0.25s;
  min-width: 232px;
  width: resizeNav(260);

  nav {

    .navigation-list {
      padding: 8px 0;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        &.menu-item {
          display: block;
          font-size: $font_size_body2;
          height: unset !important;
          min-height: resizeNav(40);
          padding: 0;
          position: relative;
          padding-right: 16px;
          //width: 100%;
        }
        &.submenu-item {
          display: block;
          padding: 0;
          position: relative;
          width: 100%;

          button {
            min-height: resizeNav(30);
            padding-left: resizeNav(64);
            padding-right: 0;
          }
        }
      }
    }

    button, button:link, button:hover, button:focus, button:active {
      font-weight: 400;
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: row;
      line-height: resizeNav(40);
      text-decoration: none;
      position: relative;
      text-transform: capitalize;
      padding-bottom: 0px;
      //padding-left: 24px;
      //padding-right: 24px;
      padding-top: 0px;
      margin-left: resizeNav(8);
      padding-left: resizeNav(10);
      padding-right: 0px;
      margin-right: 0px;
      box-sizing: border-box;
      cursor: pointer;
      min-height: resizeNav(40);
      font-size: $font_size_body2;

      .mdc-button__label {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .menu-icon {
        align-items: center;
        display: flex;
        left: 0;
        height: resizeNav(24);
        width: resizeNav(24);
        font-size: resizeNav(24);
        justify-content: center;
        margin-bottom: 0px;
        margin-right: resizeNav(24);
        position: relative;
        vertical-align: middle;
      }

      &.highlight-item {
      }
      &.focused {
        font-weight: 600;
      }
      &.active-link {
        font-weight: 600;
        .menu-icon {
          //  color: mat-color($app-accent, A100);
        }
      }
    }

    a:hover {
      background-color: mat-color($app-primary, 400);
    }

    .submenu {
      line-height: resizeNav(30) !important;
      height: resizeNav(30) !important;
      font-size: $font_size_body2;
      //&.active-link:not(:hover) {
      //  font-weight: 600 !important;
      //}
    }

    .indicator {
      position: absolute;
      width: resizeNav(40);
      height: resizeNav(40);
      right: 0;
      top: 0;
      display: flex;
      align-items: center;

      mat-icon {
        font-size: resizeNav(20);
        line-height: resizeNav(30);
        position: absolute;
        top: resizeNav(5);
        left: resizeNav(10);
      }
    }

    .open > .indicator {
      transform: rotate(180deg);
    }
  }

}

.multiple-audit-navigation {
  overflow-x: hidden;
  min-width: resizeNav(40) !important;
  transition: width 0.25s;
  -webkit-transition: width .25s;
  -moz-transition: width 0.25s;
  min-width: 232px;
  background-color: #FAFAFA !important;
  color: #303e4d !important;
  width: 220px;

  nav {

    .navigation-list {
      padding: 8px 0;
      list-style-type: none;
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        &.menu-item {
          display: block;
          font-size: $font_size_body2;
          height: unset !important;
          min-height: resizeNav(40);
          padding: 0;
          position: relative;
          padding-right: 16px;
        }
        &.submenu-item {
          display: block;
          padding: 0;
          position: relative;
          width: 100%;

          button {
            min-height: resizeNav(30);
            padding-left: resizeNav(72);
            padding-right: 0;
          }
        }
      }
    }

    button, button:link, button:hover, button:focus, button:active {
      font-weight: 400;
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: row;
      line-height: resizeNav(40);
      text-decoration: none;
      position: relative;
      text-transform: capitalize;
      padding-bottom: 0px;
      padding-top: 0px;
      margin-left: resizeNav(8);
      padding-left: resizeNav(10);
      padding-right: 0px;
      margin-right: 0px;
      box-sizing: border-box;
      cursor: pointer;
      min-height: resizeNav(40);
      font-size: $font_size_body2;

      .mdc-button__label {
        display: flex;
        align-items: center;
        width: 100%;
      }

      .menu-icon {
        align-items: center;
        display: flex;
        left: 0;
        height: resizeNav(24);
        width: resizeNav(24);
        font-size: resizeNav(24);
        justify-content: center;
        margin-bottom: 0px;
        margin-right: resizeNav(24);
        position: relative;
        vertical-align: middle;
      }
      &.focused {
        font-weight: 600;
      }
      &.active-link {
        font-weight: 600;
      }
    }

    a:hover {
      background-color: mat-color($app-primary, 400);
    }

    .submenu {
      line-height: resizeNav(30) !important;
      font-size: $font_size_body2;
      text-transform: none;
      text-align: left;
      font-weight: normal;
      border-radius: 0px;
      position: relative;
      height: 40px !important;
      display: flex;
      align-items: center;
      flex-direction: row;

      .submenu-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 150px;
        height: 40px;
        vertical-align: middle;
        line-height: 40px;
      }
    }

    .indicator {
      position: absolute;
      width: resizeNav(40);
      height: resizeNav(40);
      right: 0;
      top: 0;
      display: flex;
      align-items: center;

      mat-icon {
        font-size: resizeNav(20);
        line-height: resizeNav(30);
        position: absolute;
        top: resizeNav(5);
        left: resizeNav(10);
      }
    }

    .open > .indicator {
      transform: rotate(180deg);
    }
  }

}

.mat-sidenav-container.mini {

  .site-navigation {
    width: resizeNav(64);
    text-align: left;

    .submenu, .label, .indicator {
      display: none !important;
    }
  }

  .mini-navigation-hover {
    width: resizeNav(250);
    text-align: left;

    .submenu, .label, .indicator {
      display: block !important;
    }
  }
}

.multiple-audit-navigation-container.mini {
  .multiple-audit-navigation {
    width: resizeNav(64);
    text-align: left;

    .submenu, .label, .indicator, .horizontal-line, .badge {
      display: none !important;
    }
  }

  .mini-navigation-hover {
    width: 220px !important;
    text-align: left;

    .submenu, .label, .indicator, .horizontal-line, .badge {
      display: block !important;
    }
  }
}

.navigation-container-main > .mat-sidenav-content {
  margin-left: resizeNav(260)  !important;
}

.navigation-container-main.mini > .mat-sidenav-content {
  margin-left: resizeNav(64)  !important;
  transition-duration: .25s !important;
  transition-timing-function: ease !important;
}

.navigation-container-main > .mat-sidenav-content, .navigation-container-main mat-sidenav.mini-navigation-hover ~ .mat-sidenav-content {
  transition: margin-left 0.25s;
  -webkit-transition: margin-left .25s;
  -moz-transition: margin-left 0.25s;
}

.mat-drawer-transition .mat-drawer-content {
  transition-duration: .4s !important;
  transition-timing-function: ease !important;
}

.title-button {
  .indicator {
    mat-icon {
      left: 3px !important;
    }
  }
}

.submenu-item .check-good mat-icon {
  height: 20px;
  width: 20px;
  font-size: 20px;
  line-height: 20px;
}

@media screen and (max-width: 450px) {
  .site-navigation {
    width: 100%;
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
