//@import '~@angular/material/core/theming/theming';
@import "@angular/material/_theming";
@import "../assets/themes/default";
@import "./variables";

body {
  font-family: $font-family;
  font-size: $font-size;
  margin: 0;
  padding: 0;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
}

[hidden] {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: 300;
}

h1 {
  font-size: 24px;
}

h2 {
  font-size: 20px;
}

h3 {
  font-size: 16px;
}

h4 {
  font-size: 13px;
  margin: 0;
}

h5 {
  font-size: 11px;
}

a {
  cursor: pointer;
}

.strong {
  font-weight: 700;
}

.mat-primary {
  text-transform: none !important;
}

.mat-card-title.no-padding {
  margin-bottom: 0;
}

.badge {
  border-radius: 18px;
  display: inline-block;
  margin: 0 1px 1px 0;
  font-size: 0.9em;
  padding: 2px 4px;
  opacity: 0.7;
}

.modal-box {
  height: 490px;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

.mat-mdc-dialog-content {
  max-height: 95vh !important;
}

.mat-drawer-content {
  overflow: hidden !important;
}

a:link,
a:visited,
a:hover,
a:focus,
a:active {
  color: mat-color($app-primary);
  text-decoration: dashed !important;
}

.example-fill-remaining-space,
.fill-remaining-space {
  flex: 1 1 auto;
}

.ca-min-height-40 {
  min-height: 40px !important;
  max-height: 40px !important;
}

/* temp fix for 1789 - TODO: remove once left/right padding is updated/decided appwide */
.customer-inventory-pricing-grid tr td:first-child,
.customer-inventory-pricing-grid tr th:first-child,
.demarc-grid tr td:first-child,
.demarc-grid tr th:first-child {
  padding-left: 12px !important;
}

.customer-inventory-pricing-grid tr td:last-child,
.customer-inventory-pricing-grid tr th:last-child,
.demarc-grid tr td:last-child,
.demarc-grid tr th:last-child {
  padding-right: 12px !important;
}

.grid-with-checkboxes tr td:first-child,
.grid-with-checkboxes tr th:first-child {
  padding-left: resize(16) !important;
  text-align: left !important;
  .dx-datagrid-checkbox-size {
    line-height: 1em !important;
  }
}

.grid-with-selection-badge col.dx-col-fixed dx-checkbox,
.grid-with-selection-badge col.dx-col-fixed grid-selection-badge-container {
  width: resize(40) !important;
}

.grid-with-selection-badge [role="columnheader"] {
  padding-left: resize(8) !important;
  padding-right: resize(8) !important;

  a {
    width: 100% !important;
  }
}

.grid-with-selection-badge [role="columnheader"]:first-child {
  padding-right: resize(0) !important;
  a {
    width: 100% !important;
  }
}

.grid-with-selection-badge tr td:first-child,
.grid-with-selection-badge tr th:first-child {
  padding-left: resize(16) !important;
  text-align: left;
  .dx-datagrid-checkbox-size {
    line-height: 1em !important;
  }
}

.charge-browser-grid tr td:first-child,
.charge-browser-grid tr th:first-child {
  padding-left: 17px !important;
  .dx-datagrid-checkbox-size {
    line-height: 1em !important;
  }
}

.grid-with-selection-badge-inner tr td:first-child,
.grid-with-selection-badge-inner tr th:first-child {
  padding-left: resize(2) !important;
  .dx-datagrid-checkbox-size {
    line-height: 1em !important;
  }
}

.card-grid {
  tr td:first-child,
  tr th:first-child {
    padding-left: 12px !important;
  }
  tr td:last-child,
  tr th:last-child {
    padding-right: 12px !important;
  }
  &.no-pager {
    .dx-datagrid {
      .dx-datagrid-rowsview {
        padding-bottom: 0px !important;
      }
    }
  }
}

.muted {
  opacity: 0.4;
}

.margin {
  margin: 10px;
}

.no-margin {
  margin: 0 !important;
}

.margin-bottom {
  margin-bottom: 10px !important;
}

.margin-top {
  margin-top: 10px !important;
}

.margin-top-15 {
  margin-top: 15px !important;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-top-minus-20 {
  margin-top: -20px !important;
}

.margin-top-minus-48 {
  margin-top: -48px !important;
}

.float-right {
  float: right;
}

.hidden {
  display: none !important;
}

.visibility-hidden {
  position: absolute;
  visibility: hidden !important;
  z-index: -100;
}

hr {
  height: 1px;
}

.no-margin-left {
  margin-left: 0;
}

.no-margin-bottom {
  margin-bottom: 0;
}

.no-margin-top {
  margin-top: 0 !important;
}

.no-padding-left {
  padding-left: 0 !important;
}

.no-padding-top {
  padding-top: 0 !important;
}

.no-padding {
  padding: 0 !important;
}

.padding-top-10 {
  padding-top: 10px !important;
}

.center {
  text-align: center;
}

.right {
  text-align: right;
}

.bold {
  font-weight: bold !important;
}

.text-removed {
  text-decoration: line-through !important;
}

.details-item {
  padding-bottom: 12px;

  mat-chip {
    margin-bottom: 5px;
    margin-right: 5px;
  }
}

/* Entry Details*/

.entry-list {
  .entry-list-item {
    label {
      font-size: 0.8em;
      opacity: 0.5;
    }

    p {
      margin: 3px 0 6px 0;
    }
  }
}

.entry-list-standard {
  .entry-list-item {
    label {
      font-size: 0.9em;
      opacity: 1;
    }

    p {
      margin: 3px 0 6px 0;
    }
  }
}

.details {
  .details-side {
    box-sizing: border-box;
    margin: 10px 10px 0 10px;
  }

  .details-main {
    box-sizing: border-box;
    margin: 10px 10px 0 0;
  }
}

.filter .filter-content {
  padding: 15px;
}

[mat-card-avatar] {
  img {
    width: 100%;
    height: 100%;
  }
}

.note-avatar {
  float: left;
  cursor: pointer;
  border-radius: 100%;
  width: 40px;
  height: 40px;

  .replacement {
    align-items: center;
    display: inline-grid;
    text-align: center;
  }
}

.note-card-title {
  padding-left: 8px;
}

.note-card-header:after {
  clear: both;
}

.note-card-user {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 99%;
}

.note-card-body {
  word-break: break-all;
  padding-left: 0px;
}

.ca-card-note .mdc-button[disabled] {
  opacity: 0.5;
}

.note-card-body:after {
  clear: both;
}

.card-cozy {
  padding: 10px;
  mat-card-header {
    margin: 0;
    height: 20px !important;
    .mat-card-header-text {
      margin: 0 !important;
      height: 100% !important;
    }
  }
}

mat-card-content > :last-child,
mat-card > :last-child {
  word-wrap: break-word !important;
}

.switch-button {
  padding: 0 6px 0 6px !important;
  margin: 0;
  line-height: 22px !important;
  border-radius: 3px;
  font-size: 11px !important;
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;
  border: none;
  outline: none;
}

.btn-disabled[disabled] {
  opacity: 0.5;
}

.dictionary-reorder {
  float: right;
  &:hover {
    color: mat-color($app-primary);
  }
}

.small-text {
  font-size: 12px;
}

.bottom-padding {
  padding-bottom: 15px;
}

.bottom-padding-10 {
  padding-bottom: 10px !important;
}

.no-background {
  background: none !important;
}

.gl-coding-picker {
  mat-select {
    min-width: 150px;
  }
}

.gl-custom-rule-spacing {
  padding-right: $ca-form-flex-item-spacing;
}

.chart-filter-field-item {
  width: 270px;
}

.chart-filter-field-item-option {
  width: 200px;
}

.absolute-position {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  left: 0 !important;
}

.page-size-select {
  display: flex;

  .page-size-dropdown {
    padding-top: 0;
  }

  .mat-mdc-select-value {
    right: 20px;
    left: unset;
  }

  .mat-input-infix {
    border-top: 0;
  }

  .mat-mdc-select-trigger {
    width: fit-content !important;
  }

  // div {
  //   min-width: 40px !important;
  // }
}

.mat-select.read-only {
  .mat-select-arrow,
  .mat-select-underline {
    display: none !important;
  }
}

// format error for date-time picker TODO: confirm that is not in use and remove
.dx-invalid.dx-texteditor .dx-texteditor-container:after {
  display: none;
}

.dx-invalid-message > .dx-overlay-content {
  padding: 0px;
  font-size: 75%;
  top: 1px !important;
  width: 100% !important;
}

.foc-date-grid {
  table tr td:first-child {
    padding-left: 7px !important;
  }
}

.foc-field-margin {
  mat-select {
    margin-top: 25px;
  }
}

.empty-sneak-peek-note {
  display: flex;
  height: 100%;
  width: 100%;
  p {
    text-align: center;
    margin: auto;
  }
}

.icon-flipped-vertical {
  transform: scaleY(-1);
  -moz-transform: scaleY(-1);
  -webkit-transform: scaleY(-1);
  -ms-transform: scaleY(-1);
}

.quoting-vendor-grid-select {
  table tr td:first-child {
    padding-left: 0 !important;
  }
}

.srm-content {
  position: absolute !important;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;

  /*.flex-container .flex-item:last-child:not(:first-child) {
    padding-left: 15px;
  }

  .flex-container .flex-item-multiple:not(:first-child) {
    padding-left: 15px;
  }

  .flex-container .flex-item-multiple-even:nth-child(even) {
    padding-left: 15px;
  }*/

  .dx-texteditor-input {
    padding-left: 0px !important;
    padding-bottom: 0px !important;
  }

  .mat-floating-placeholder {
    top: -12px !important;
  }

  textarea {
    resize: none;
  }

  .date-label {
    font-size: 0.7em;
    position: relative;
    top: 30px;
    left: 20px;
    z-index: -1;
    transition: all 0.5s ease 0s;
  }

  .data-label-show {
    top: 12px;
    left: 0;
    z-index: 2;
  }

  .dx-placeholder {
    top: 4px;
  }

  .mat-input-underline .mat-input-ripple {
    height: 1px;
  }
}

.srm-load-status {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  background: transparent;

  p {
    text-align: center;
    font-size: 18px;
    opacity: 0.6;
    margin: auto;
  }
}

.srm-pivot {
  box-sizing: border-box;

  table tbody.dx-pivotgrid-vertical-headers tr td {
    padding-left: 10px !important;
  }
}

/* The only way to target Pivot Table in Dashboard */
/*.dx-pivotgrid, .srm-pivot,
.ca-pivot-table.srm-pivot.ca-default-border-right.details-main.no-margin-botto.dx-widget.dx-visibility-change-handler.dx-pivotgrid.dx-row-lines.dx-pivotgrid-fields-container.dx-sortable
> .dx-pivotgrid-container
> .dx-word-wrap.dx-pivotgrid-border {
  height: 100% !important;
}*/

/* hack to hide Grand Total header when pivot do not have columns */
.no-columns-pivot {
  .dx-pivotgrid-horizontal-headers tr:first-child {
    display: none !important;
  }
}

.srm-legend-settings {
  padding-left: 0 !important;
}

.ca-dropzone {
  min-height: 100px;
  display: flex;
  width: 100%;
  border-width: 1px;
  border-style: dashed;
  position: relative;

  .text-wrapper {
    margin: auto;

    .upload-icon {
      width: 48px;
      height: 48px;
      font-size: 48px;
      margin-right: 16px;
    }

    .document-upload-text {
      font-size: 14px;
    }

    .document-upload-button {
      min-width: unset;
      padding-left: 6px;
      padding-right: 6px;
      margin-left: 8px;
    }
  }

  .selected-file {
    position: absolute;
    bottom: 8px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.mat-autocomplete-panel {
  //position: absolute !important;
  //right: 0;

  //min-width: 250px !important;
  //max-width: 800px !important;

  .mat-optgroup {
    border-bottom: 1px solid;
    //display: block;

    .mat-optgroup-label {
      display: none;
    }
  }
}

.ca-form ca-address-selection-form {
  padding-bottom: 1.25em;
}

.address-positon-padding-fix {
  form {
    div.flex-container:first-of-type {
      padding-top: 0 !important;
    }
  }
}

ca-address-selection.quote-address-vertical .ca-address-container {
  min-height: 8em !important;
}

app-contact-manage-dialog {
  ca-address-selection {
    .ca-address-container {
      min-height: 15em;
      max-height: 15em;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .address-form-container,
    .entry-list {
      width: 100%;
    }
  }
}

ca-address-selection {
  .ca-address-container {
    padding: 1.3333333em;
    min-height: 16em;
    align-items: center;
    position: relative;
    box-sizing: border-box;

    .mat-mdc-form-field {
      padding-top: 0 !important;
    }

    .add-non-verified {
      position: absolute;
      right: 10px;
      bottom: 22px;
    }
  }

  ca-subdivision-picker {
    mat-select.mat-select {
      padding-top: 0 !important;
    }
  }

  .mat-mdc-card {
    padding: 0 !important;
    box-shadow: none;

    .mat-card-header {
      margin: 0 !important;
    }

    .mat-mdc-card-content {
      padding: 0;

      .autocomplete-container {
        .mat-input-placeholder-wrapper {
          .mat-input-placeholder.mat-float.mat-focused,
          .mat-input-placeholder.mat-float:not(.mat-empty) {
            display: none !important;
          }
        }
      }
    }

    .empty-state {
      justify-content: center;
      .entry-list .entry-list-item p {
      }
    }

    .address-form-container {
      padding-left: 10px !important;
      padding-right: 10px !important;
    }
  }
}

app-email-tag {
  overflow-y: auto;

  tag-input {
    padding: 0 !important;

    tag {
      margin: 2px !important;
    }
  }
}

app-email-tag delete-icon svg {
  height: 20px !important;
}

app-email-tag tag {
  height: 22px !important;
  line-height: 22px !important;
  font-size: 0.8em !important;
}

app-email-tag .ng2-tag-input__text-input {
  padding: 0px !important;
  height: 28px !important;
  margin-top: 0px;
}

.step-3 table tr td:first-child {
  padding-left: 0 !important;
}

/* PICKER*/

.ca-picker {
  overflow: hidden !important;
  position: relative;

  .mat-mdc-form-field-infix {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
  }

  .ca-picker-search-container.mat-accent {
    //background-color: transparent !important;
  }

  .ca-picker-search-container {
    display: block !important;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    margin: 0;
  }

  .ca-picker-search-container .mat-mdc-text-field-wrapper {
    padding-bottom: 0 !important;
  }

  .ca-picker-search-container input {
    width: 100%;
    min-height: 32px;
    max-height: 32px;
    padding: 0 12px;
    line-height: 26px;
    //font-style: italic;
    overflow: hidden;
    box-sizing: border-box;
  }

  .ca-picker-entries {
    margin-top: 0;
    min-height: 32px;
    max-height: 180px;
    overflow-x: hidden;
    overflow-y: auto;
    &.two-line-entries {
      max-height: 192px !important;
    }
  }
}

.ca-picker-searchable .ca-picker-entries {
  margin-top: 57px;
}

/* Empty state container */
.ca-empty-state-container {
  display: flex;
  height: 100%;
  width: 100%;
  text-align: center;
  font-size: 14px;

  p,
  span {
    width: 100%;
    text-align: center;
    margin: auto;
  }

  &.history-container {
    height: 90%;
  }

  .card-message {
    opacity: 0.5;
    font-size: 14px;
  }

  .grid-message {
    opacity: 0.5;
    font-size: 18px;
  }

  .span-message {
    opacity: 0.5;
    font-size: 14px;
  }

  .small-div-message {
    opacity: 0.5;
    font-size: 16px;
  }

  .title {
    text-align: center;
    margin: auto;
  }
}

.dx-datagrid-nodata,
.cropper-loader p {
  opacity: 0.5;
  font-size: 18px;
}

.loading-chart-div {
  .loading-chart-indicator {
    position: absolute;
    top: 30%;
    left: 50%;
    opacity: 0.5;
    font-size: 16px;
  }
}

.mat-raised-button.switch-button.approve-btn {
  min-width: 88px;
}

.mdc-button .mdc-button__label > ca-icon,
.mdc-icon-button .mdc-button__label > ca-icon,
.mat-raised-button .mdc-button__label > ca-icon {
  vertical-align: unset;
}

.mdc-button .mdc-button__label ca-icon > *,
.mdc-icon-button .mdc-button__label ca-icon > *,
.mat-raised-button .mdc-button__label ca-icon > * {
  vertical-align: middle;
}

/* Fox for inconsistent underline */
.mat-form-field-underline,
.mat-select-underline {
  transform: perspective(1px) !important;
}

.ca-network-hub-accordion-heading-toolbar,
ca-network-hub-accordion-heading-toolbar .mat-toolbar-row {
  height: 40px !important;
}

.mat-mdc-form-field-infix {
  width: auto !important;
}

.app-search-dropdown {
  max-height: 400px !important;
}

/* Image cropper */
.cropper .move {
  border-radius: 100% 100% !important;
}

image-cropper.vendor-cropper {
  .cropper .move {
    border-radius: 0 !important;
  }
}

ca-avatar.vendor-avatar {
  .avatar {
    border-radius: 0 !important;
  }
}

image-cropper#file-source-cropper,
image-cropper#file-source-cropper div:first-child,
image-cropper#file-source-cropper div:first-child img {
  width: 100% !important;
  max-width: 100% !important;
  max-height: 100% !important;
}

.mat-mdc-list.mat-mdc-list-base {
  padding-top: 0;
}

.permission-list-item {
  border-bottom: 1px solid;
  border-color: rgba(48, 62, 77, 0.12) !important;
}

.permission-span {
  padding-right: 23px;

  &.name-width {
    display: inline-flex;
    width: 145px;
    overflow-wrap: break-word;
  }
}

.mat-mdc-option {
  box-sizing: border-box;
}

.mdc-dialog__title::before {
  height: 0 !important;
}

.mat-mdc-form-field-hint-wrapper {
  padding: 0 !important;
}

.mdc-menu-surface.mat-mdc-select-panel {
  padding: 0 !important;
}

div.mat-mdc-autocomplete-panel {
  padding: 0 !important;
}
