@import "../variables";

.filter-group {

  .dx-texteditor-input,
  .dx-placeholder,
  input {
    font-weight: 300;
  }

  rl-tag-input {
    margin: 0!important;

    rl-tag-input-item {
      margin-bottom: 1px;
    }
  }

  .address-entry-container {
    position: relative !important;
    .field-commands {
      position: absolute;
      bottom: 35px;
      right: 0;
      z-index: 9;
      opacity: 0.7;
      font-size: $ca-filter-icon-size;
    }

    mat-icon{
      height: $ca-filter-icon-size;
      width: $ca-filter-icon-size;
      font-size: $ca-filter-icon-size;

      :nth-last-child{
        text-align: right;
      }
    }
  }
}

.iconForTextFilter{
  width: 20px !important;
  height: 12px !important;
  font-size: 14px !important;
}

.ca-height-0 {
    height: 0;
    overflow: hidden;
  }


  .ca-filter {
    mat-card-header {
      margin-bottom: 0 !important;

      .mat-card-title {
        align-items: center;
        display: flex;
      }
      .input-command {
        font-size: $ca-filter-icon-size;

        mat-icon{
          height: $ca-filter-icon-size;
          width: $ca-filter-icon-size;
          font-size: $ca-filter-icon-size;

          :nth-last-child{
            text-align: right;
          }
        }
      }
    }

    .ca-filter-card {
      .ca-form {
        mat-form-field {
          padding-top: 0 !important;
        }
      }
    }

    mat-card-content {
      padding: 0 !important;

      mat-form-field{
        padding: 0;
      }

      tag-input {
        height: auto !important;
        .ng2-tags-container {
          tag {
            margin: 0.1em 0.3em 0.1em 0 !important;
          }
        }
      }
    }
  }

  .mat-mdc-menu-item {
    line-height: resize(45);
    height: resize(45);
    font-size: $font_size_button !important;
  }
