[hiDensityInput] {
  .mdc-text-field {
    border-radius: 2px 2px 0 0;
    min-height: resizeHiDens(56, -12);
    padding: 0px 0px !important;

    .mat-mdc-form-field-icon-prefix {
      align-self: center;
      margin-left: resizeHiDens(12);
    }

    .mat-mdc-form-field-infix {
      margin-left: resizeHiDens(12);
      padding: 0;
      border-top: none;
      display: flex;
      align-items: baseline;

      input,
      mat-select,
      textarea {
        //ammounts to 16dp guideline for Roboto
        font-size: $font_size_body2;
        line-height: resizeHiDens(20);
        //compensation for baseline calculation
        margin-top: resizeHiDens(26, -8);

        .mat-mdc-select-value-text {
          line-height: resizeHiDens(20);

          span {
            display: block;
            font-size: resizeHiDens(15);
            line-height: resizeHiDens(20);
            margin-right: resizeHiDens(20);
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }

        label {
          top: resizeHiDens(26, -8) !important;
        }
      }

      input {
        &[type="number"] {
          -moz-appearance: textfield;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }

      textarea {
        margin-bottom: resizeHiDens(8);
        resize: none;
        overflow-y: auto;
      }

      .mdc-floating-label {
        //ammounts to 16dp guideline for Roboto
        font-size: resizeHiDens(15);

        .mat-placeholder-required {
          margin-left: -3px !important;
        }
      }

      .mat-mdc-select-trigger {
        width: 100%;
        padding-right: 5%;

        .mat-select-arrow-wrapper {
          top: 0;
          right: 0;
          position: absolute;
          margin-top: resizeHiDens(2);
          margin-right: resizeHiDens(8);
          height: resizeHiDens(24);
          width: resizeHiDens(24);
        }
      }
    }
    .mat-mdc-form-field-icon-suffix {
      [matsuffix] {
        font-size: resizeHiDens(15);
        line-height: resizeHiDens(20);
      }
    }
  }

  &.mat-form-field-disabled,
  .mat-form-field-disabled {
    .mat-mdc-form-field-infix {
      opacity: 0.7;
    }

    .mat-form-field-underline {
      background-image: none;
      bottom: 0;
    }
  }

  .mat-form-field-subscript-wrapper {
    margin-top: resizeHiDens(4);
    top: auto !important;
  }

  &.mat-form-field-can-float.mat-form-field-should-float,
  .mat-form-field-can-float.mat-form-field-should-float,
  &.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper,
  .mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper,
  &.mat-form-field-can-float
    .mat-form-field-autofill-control:-webkit-autofill
    + .mat-form-field-label-wrapper,
  .mat-form-field-can-float
    .mat-form-field-autofill-control:-webkit-autofill
    + .mat-form-field-label-wrapper {
    .mdc-floating-label {
      transform: translateY(resizeHiDens(-8)) scale(0.75) perspective(100px)
        translateZ(0.001px) !important;
      -ms-transform: translateY(resizeHiDens(-8)) scale(0.75) !important;
    }
  }

  .mat-datepicker-toggle {
    .mdc-icon-button {
      position: absolute;
      margin-left: resizeHiDens(-45);
      margin-top: resizeHiDens(-26, 2);
    }
  }

  .mat-select-arrow {
    margin-left: auto;
    margin-right: auto;
  }

  &.picker-without-label {
    .mat-select {
      margin-top: unset !important;
    }
    .mat-select-arrow {
      margin: resizeHiDens(6) !important;
    }
    .mat-input-infix.mat-mdc-form-field-infix {
      //margin-top: auto;
      //margin-bottom: auto;
    }
  }

  .grid-settings-fields,
  .srm-interactive-fields {
    ::ng-deep .mat-mdc-text-field-wrapper {
      padding-bottom: 0 !important;
    }
  }

  &.currency-without-label {
    .mat-input-infix.mat-mdc-form-field-infix {
      //margin-top: auto !important;
      //margin-bottom: auto !important;
      input {
        margin: unset !important;
      }
    }
  }
}
