@import "../variables";

.ca-card {
  margin: 8px !important;

  &.ca-card-note {
     margin: 0 !important;
  }

  &.ca-card-filter, &.ca-card-sneak-peek {
    //margin-left: 3px !important;

    mat-card {
      margin: 0px !important;
      padding: 10px !important;

      mat-card-header {
        padding: 0 !important;
        margin: 0 !important;
        font-weight: 500;
      }
    }
  }

  &.ca-card-sneak-peek{
    mat-card {
      padding-top: 13px !important;
      padding-bottom: 10px !important;
      padding-left: 16px !important;
      padding-right: 16px !important;
    }
  }

  mat-card {
    height: unset;
    margin: 8px !important;
    font-size: $font-size;
    padding: 16px;
    padding-bottom: 30px;

    .mat-mdc-card-content{
      padding: unset;
    }

    &.card-table {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;

      &.card-table-100 {
        height: 100%;
      }

      &.card-table-bottom{
        bottom: 16px !important;
      }

      &.card-table-no-header {
        mat-card-content {
          top: 16px !important;
        }
      }

      mat-card-content {
        position: absolute;
        top: 60px !important;
        left: 16px !important;
        right: 16px !important;
        bottom: 24px !important;
      }
    }

    mat-card-header {
      padding: 0;
      padding-top: 8px !important;
      padding-bottom: 16px !important;
      margin: 0 !important;

      .mat-card-header-text {
        padding: 0;
        height: auto;
        margin: 0;
      }
    }

    mat-card-content {
      &.inner-content {
        padding-left: unset !important;
      }

      label {
        opacity: 1 !important;
      }
    }
  }
}


// Invoice details - summary of charges
.invoice-summary-card {
  padding: 12px 7px !important;
  height: 100% !important;

  .card-list-entry {
    padding: 3px 5px !important;
    margin-bottom: 2px;
  }

  .card-list-entry-bgr {
    padding: 3px 5px;
    margin-bottom: 2px;
  }
}


.dispute-summary-card {
  padding: 12px 12px !important;

  .card-list-entry {
    padding: 3px 5px 7px 5px !important;
  }

  .card-list-entry-bgr {
    padding: 3px 5px 7px 5px;
  }
}


//DEFAULT CARD STYLE. REMOVE WHEN APPLY ca-form ON ALL PAGES
.default-card {
  *, .mat-mdc-dialog-content{
    .dx-widget {
      font-family: $font-family !important;
    }
    .mat-mdc-card {
      padding: 12px;

      .mat-card-header {
        padding: 0;
        height: auto;
        //opacity: .7;
        margin-bottom: 8px;

        .mat-card-header-text {
          padding: 0;
          height: auto;
          margin: 0;

        }
      }
    }
  }

  .mat-mdc-card {
    font-size: $font-size;
    .card-list-entry {
      padding: 6px 0;
    }
    .mat-card-header {
      position: relative;
      .card-commands {
        position: absolute;
        top: 0;
        right: 0;

        mat-icon {
          font-size: .8em !important;
        }
      }
    }
  }

  .card-table {
    .mat-mdc-card,
    .mat-mdc-card-content {
      padding: 0 !important;
    }

    span, .mat-icon {
      font-weight: 300;
    }

    .title {
      font-size: 14px !important;

    }

    mat-toolbar {
      padding: 0;
    }
  }
}


.ca-card-links {

  .ca-list-item {
    cursor: pointer;
  }

  .list-icon {
    height: 80px;
    width: 80px;
    font-size: 80px;
    margin-top: -8px;
    padding-left: 8px;
  }

  .contract-icon {
    .list-icon {
      height: 50px;
      width: 50px;
      font-size: 50px;
    }
  }

  .item-title{
      font-size: 18px;
  }

  .card-details{
      font-size: 14px;
      opacity: 0.8;
      padding-top: 16px;
  }

  .ca-list-item:hover {
      box-shadow: 0 8px 8px rgba(0,0,0,0.25), 0 8px 8px rgba(0,0,0,0.22), 0 8px 8px rgba(0, 0, 0, .28);
  }
}

.ca-text-ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ca-group-divided {
  padding: 0 5px 5px 5px;
  label {
    padding-top: 5px;
    display: block;
  }
  .ca-checkbox-small-padding label {
    padding-top: 0.8em !important;
  }
}

.mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: #FAFAFA !important;
}
