[caInput],
[loginInput] {
  //We had issue with auto backgorund color on input filed. This resolved this problem
  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition:
      background-color 0s 600000s,
      color 0s 600000s !important;
  }

  .mat-mdc-text-field-wrapper {
    height: auto;
    border-radius: 2px 2px 0 0;
    min-height: resize(56);
    padding: 0;

    .mat-mdc-form-field-icon-prefix {
      align-self: center;
      margin-left: resize(12);
    }

    .mat-form-field-prefix {
      align-self: center;
      margin-left: resize(12);
    }

    .mat-mdc-form-field-infix {
      margin-left: resize(12);
      padding: 0;
      border-top: none;
      display: flex;
      align-items: baseline;

      input,
      mat-select,
      textarea {
        //ammounts to 16dp guideline for Roboto
        font-size: $font_size_body2;
        line-height: resize(20);
        //compensation for baseline calculation
        margin-top: resize(26);
        padding-right: 8px;

        .mat-mdc-select-value-text {
          line-height: resize(20);

          span {
            display: block;
            font-size: $font_size_body2;
            line-height: resize(20);
            margin-right: resize(20);
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      input {
        &[type="number"] {
          -moz-appearance: textfield;
          &::-webkit-outer-spin-button,
          &::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
        }
      }

      textarea {
        margin-bottom: resize(8);
        resize: none;
        overflow-y: auto;
      }

      .mdc-floating-label {
        //ammounts to 16dp guideline for Roboto
        font-size: resize(15);

        .mat-placeholder-required {
          margin-left: -3px !important;
        }
      }

      .mat-mdc-select-trigger {
        width: 100%;
        .mat-select-arrow-wrapper {
          top: 0;
          right: 0;
          position: absolute;
          margin-top: resize(2);
          margin-right: resize(8);
          height: resize(24);
          width: resize(24);
        }
      }
    }
    .mat-mdc-form-field-icon-suffix {
      margin-right: resize(12) !important;

      [matsuffix] {
        font-size: resize(15);
        line-height: resize(20);
      }
    }
  }

  &.mat-form-field-disabled,
  .mat-form-field-disabled {
    .mat-mdc-form-field-infix {
      opacity: 0.7;
    }

    .mat-form-field-underline {
      background-image: none;
      bottom: 0;
    }
  }

  .mat-form-field-subscript-wrapper {
    margin-top: resize(4);
    top: auto !important;
  }

  &.mat-form-field-can-float.mat-form-field-should-float,
  .mat-form-field-can-float.mat-form-field-should-float,
  &.mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper,
  .mat-form-field-can-float
    .mat-input-server:focus
    + .mat-form-field-label-wrapper,
  &.mat-form-field-can-float
    .mat-form-field-autofill-control:-webkit-autofill
    + .mat-form-field-label-wrapper,
  .mat-form-field-can-float
    .mat-form-field-autofill-control:-webkit-autofill
    + .mat-form-field-label-wrapper {
    .mdc-floating-label {
      transform: translateY(resize(-8)) scale(0.75) perspective(100px)
        translateZ(0.001px) !important;
      -ms-transform: translateY(resize(-8)) scale(0.75) !important;
    }
  }

  &.color-input {
    .mat-form-field-prefix,
    input,
    label {
      padding-top: resize(2);
    }
  }

  .mat-datepicker-toggle {
    .mdc-icon-button {
      position: absolute;
      margin-left: -(resize(25));
      margin-top: -(resize(26));
    }
  }

  .mat-select-arrow {
    margin-left: auto;
    margin-right: auto;
  }

  &.picker-without-label {
    .mat-select {
      margin-top: unset !important;
    }
    .mat-select-arrow {
      margin: resize(6) !important;
    }
    .mat-input-infix.mat-mdc-form-field-infix {
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}
