//@import '~@angular/material/core/theming/theming';
@import '@angular/material/_theming';

.input-right-align {
  text-align: right;
}

input.input-right-align::-webkit-outer-spin-button,
input.input-right-align::-webkit-inner-spin-button {
  display: none;
}

input.input-right-align {
  -moz-appearance: textfield;
}

.login-fields{
  height: 180px;
}
.reset-fields {
  height: 150px;

  .mat-input-subscript-wrapper {
    z-index: 5;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus
input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border:none !important;
  -webkit-text-fill-color: inherit !important;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
}

.sign-in-form {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  app-login, app-forgot-password, app-reset-password {
    height: 100%;
    box-shadow: 1px 4px 20px #9b9797;
    a:hover {
      text-decoration: underline !important;
    }
  }

  .sign-in-form-content {
    width: 350px;
    padding: 2em;
    height: 80%;
    max-height: 750px;

    form {
      padding: 3em 2.5em 0.5em 2.5em;
    }
    .mdc-button {
      text-transform: uppercase !important;
    }
  }

  .logo-lg {
    margin: 2em auto;
    padding: 0 1em 0 1em;
    width: 234px;
    height: 80px;
    background-size: cover;
  }

  button{
    margin-top: 2.5em;
    color: #ffffff !important;
  }

  .forgot-password-link {
    margin-top: 0.5em;
    font-size: 12px;
    a:link, a:visited {
      color: #ffffff;
    }
  }
}

.sign-in {
  height: 100%;
  .sign-in-text {
    text-align: center;
    margin: 4px;
  }
  .sign-in-logo {
    padding-left: 30px;
    padding-right: 30px;
    text-align: center;
  }
  .logo-large {
    background-position: center;
    background-repeat: no-repeat;
    height: 100px;
  }
}

.circle {
  position: relative;
  margin-right: -24px;
  margin-left: -24px;
  padding-top: 24px;
  margin-bottom: 8px;
}
.circle::after {
  position: absolute;
  bottom: 0;
  display: block;
  content: '';
  border-radius: 0 0 100% 100%;
  clip-path: circle(385% at 50% -2310%);
  background: inherit;
  box-shadow: inset 0px -17px 17px -10px rgba(0,0,0,0.2);
  top: 100%;
  height: 40px;
  width: 100%;
}

.sign-in-copyright {
  color: #ffffff;
  font-size: 12px;
  bottom: 0;
  text-align: center;
  position: absolute;
  width: 100%;
  margin-left: -24px;
  margin-bottom: 4px;
  a:link, a:visited {
    color: #ffffff;
  }
  a:hover {
    text-decoration: underline !important;
  }
}

.sign-in-modal {
  height: 100%;
  border-radius: 2px;
  overflow: hidden;
  padding: 24px;
  position: relative;
}

[loginInput] {
  .mat-mdc-text-field-wrapper{
    height: resize(50);
  }
  .mdc-text-field {
    background: rgba(48, 62, 77, 0.04) !important;
  }

  &.ng-pristine .mat-form-field-underline {
    background-color: transparent !important;
  }

  .mat-mdc-text-field-wrapper:hover .mat-form-field-underline {
    background-color: #303E4D !important;
  }
}
