@use "@angular/material" as mat;
@use "../../assets/themes/default" as theme;

.mdc-dialog .mdc-dialog__content {
  padding: 0 !important;
}

.mat-mdc-dialog-container {
  padding: 0px !important;
  height: 100%;

  .mat-dialog-top-spacer {
    height: 24px;
  }

  .mdc-button__label {
    text-transform: uppercase !important;
    color: mat.get-color-from-palette($app-primary, 300) !important;
  }

  .ca-dialog-action {
    padding: 8px !important;
    padding-top: 0 !important;
    margin: 0 !important;
  }

  app-quoting-manage-dialog {
    .ca-dialog-title {
      h1 {
        font-size: $font_size_h5 !important;
      }
    }
  }

  .ca-dialog-title {
    h1 {
      font-size: $font_size_h5 !important;
      line-height: 20px;
      margin: 0 !important;
      padding: 0 !important;
    }

    font-weight: 300 !important;
    font-size: 18px !important;
    padding: 0 24px 20px;
  }

  .ca-dialog-content {
    padding: 0 24px !important;
    overflow: hidden;
    margin: 0;
    font-size: $font_size_body1;

    .dialog-content-no-margin {
      margin-top: 0;
    }

    .dialog-content-no-margin-form-child {
      form {
        margin-top: 0 !important;
      }
    }
  }
}
.checkbox-container {
  padding: 16px 0;
}
