@function resizeTab($value, $add: 0) {
  @return ($value + $add) * $tab-multiplier * 1px;
}

.ca-dash {
  display: flex;
  justify-content: space-between;
  height: resizeTab(48);
}

.app-content,
.ca-tab {
  &.tabbed-content {
    .mat-mdc-tab-group {
      .mat-tab-header-pagination {
        box-shadow: none !important;
      }

      .mat-mdc-tab-label-container {
        flex-grow: 0;
      }
    }

    .tabbed-navigation-container {
      height: resizeTab(48);

      &.with-more {
        padding-right: 40px;
        position: relative;
      }
    }

    mat-tab-header {
      padding-left: resizeTab(48);
      .mdc-tab__content{
        text-transform: uppercase;
      }
      //TODO: This need to be deleted
      .mat-tab-label {
        padding: 0 resizeTab(24);
        font: inherit !important;
        min-width: unset !important;
        font-size: $font_size_button !important;
        height: resizeTab(48) !important;

        &.mat-tab-label-active {
          background-color: unset !important;
          opacity: 1;


        }

        &:not(.mat-tab-label-active) {
          .mdc-tab__content {
            .tab-title {
              opacity: .7 !important;
            }

            .badge {
              opacity: 1 !important
            }

            .ng-star-inserted {
              opacity: 1;
            }
          }
        }
      }
    }

    .mat-tab-links {
      padding-left: 48px;
    }

    .mat-tab-link, .mat-tab-label {
      font-size: inherit !important;
    }

    .tabs {

      a {
        padding: 0 24px;
        opacity: 1;
        min-width: unset !important;

        &[ng-reflect-active^="false"] {
          opacity: .7;
        }

        &[ng-reflect-active^="true"] {
          opacity: 1;
          background-color: unset !important;
        }
      }

      .disabled {
        opacity: .7 !important;
      }

      height: 50px;
    }

    .tabs-body {
      position: absolute;
      top: resizeTab(49);
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: auto;
    }

    .tabs-disable-scroll {
      overflow-y: hidden;

    }

  }
}

.mat-tab-label {
  min-width: 30px !important;
  text-transform: uppercase !important;
  opacity: 1 !important;
}
