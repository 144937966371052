.panel-body {
  padding: 5px 15px;
}

.list-group {
  padding-left: 0;
  margin-bottom: 20px;
  height: auto;

  .mat-slide-toggle {
    margin: 0 !important;
    position: relative;
    top: 4px;
  }
}

.list-group-item {
  position: relative;
  display: block;
  padding: 10px 15px;
  margin-bottom: -1px;

  &.no-right-padding {
    padding: 10px 0 10px 15px !important;
  }
}

.sort-button {
  float: right;
}

.flex-list {

  .flex-list-item {
    padding: 15px 15px 10px 15px;
    line-height: 24px;
  }

  .flex-list-item-command {
    text-align: center;
  }
}

.hide-delete-icon-container {
  padding-top: 4px;
  padding-bottom: 4px;
  .delete-button-container {
    .mdc-icon-button {
      display: none !important;
    }
  }
}
.hide-delete-icon-container:hover {
  .delete-button-container {
    .mdc-icon-button {
      display: flex !important;
      justify-content: center;
      align-items: center;
    }
  }
}
.ca-list-group {
  padding: 0;
  height: auto;

  .ca-list-group-item {
    position: relative;
    display: block;
    padding: 3.5px 16px 3.5px 16px;
    height: 40px;
    margin-bottom: -1px;
    border-bottom-width: 1px;
    border-bottom-style: solid;

    .delete-button-container {
      .mdc-icon-button {
        display: none !important;
      }
    }

  }
  .ca-list-group-item:hover {

    .delete-button-container {
      .mdc-icon-button {
        display: inline !important;
      }
    }
  }
  .ca-list-group-item:last-child {
    border-bottom-width: 0px;
    padding: 4px 16px 4px 16px;
  }
}

