@import "../../assets/variables";

@function resizeTable($value, $add: 0) {
  @return ($value + $add) * $table-multiplier * 1px;
}

@function resizePager($value, $add: 0) {
  @return ($value + $add) * $pager-multiplier * 1px;
}


.display-freespace {
  .dx-freespace-row {
    display: block !important;
    height: 13px !important;
    width: 100%!important;
  }
}

.app-content-list {
  overflow: hidden !important;

  .dx-widget {
    max-width: 100% !important;
  }

  .mat-sidenav-content {
    overflow: hidden !important;
  }
  .mat-sidenav-content {
    overflow: hidden !important;
  }
  .dx-datagrid-rowsview {
    padding-bottom: resizePager(54) !important;
  }
  .dx-scrollbar-horizontal {
    .dx-scrollbar-hoverable {
      height: 7px;
    }
  }
  .dx-freespace-row {
    display: block !important;
    height: 13px !important;
    width: 100%!important;
  }
  .dx-select-checkbox {
    line-height: unset;
    vertical-align: unset;
    .dx-checkbox-container {
      max-width: resizeTable(20);
      max-height: resizeTable(20);
      .dx-checkbox-icon {
        width: resizeTable(20);
        height: resizeTable(20);
      }
    }
  }

  .icon-td {
    height: 100%;
    margin-left: resizeTable(-2);
    margin-top: resizeTable(4);
  }

  .dx-data-row {
    font-size: $font_size_body2 !important;
    line-height: resizeTable(14) !important;
  }

  .dx-header-row {
    font-size: $font_size_body2 !important;
    line-height: resizeTable(12, 4) !important;
  }

  .grid-selection-badge-container{
    max-height: resizeTable(18);
  }

  .dx-data-row, .dx-header-row, .dx-group-row {
    min-height: resizeTable(18) !important;
    height: resizeTable(24) !important;

    td {
      min-height: resizeTable(18) !important;
      height: resizeTable(24) !important;
      vertical-align: middle !important;
      box-sizing: content-box!important;
      margin: 0 !important;
      padding: resizeTable(12, -8) 0 resizeTable(12, -8) resizeTable(8) !important;

      &:first-child {
        padding-left: resizeTable(16) !important;
      }

      &:last-child {
        padding-right: resizeTable(16) !important;
      }
    }

    th {
      &:first-child {
        padding-left: resizeTable(16) !important;
      }
    }

    .selection-badge.grid-badge{
      font-size: $font_size_body2;
      width: resizeTable(18);
      text-align: center;
    }
  }
}

.dx-datagrid-content.dx-datagrid-content-fixed.dx-pointer-events-target {
  padding-bottom: 54px;
}
