.notes-view {
  padding: 0 10px;

  .panel-body {
    padding: 5px 0 !important;
  }

  .accordion-toggle-group {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
  }
}

.notes-add .mat-select {
  padding-bottom: 0!important;
  padding-top: 0!important;
}


.note-card-comments {
  font-size: .9em;
  padding-left: 19px;

  .note-card-title {
    padding-left: 8px;
  }

  .note-card-header {
    padding-top: 8px;
  }
}

.ca-card-note-list-group {
  .panel-body {
    padding-top: 0 !important;
  }
}

.note-card .mat-input-container,
.note-card-reply .mat-input-container {
  background-color: transparent !important;
  color: inherit !important;
}
