.history {
  font-size: .8em;
  height: 100%;
  text-transform: none;

  .history-item-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .history-item {
    padding: 5px 10px;
    margin: 5px;
    font-size: 12px;

    .history-entry-user {
      cursor: pointer;
    }

    .history-entry-item {
      cursor: pointer;
    }

    .history-entry-action {
      font-weight: bold;
    }

    .history-entry-label {
      text-transform: lowercase;
    }
  }
}
