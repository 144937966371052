.ca-tree-view-grid td.dx-master-detail-cell {
    padding: 0 !important;
}

.ca-tree-view-grid table tr td:first-child {
    padding-left: 0 !important;
}

app-contract-tree-view .dx-datagrid.dx-datagrid-borders > .dx-datagrid-rowsview {
    border: 0 !important;
}

.app-content-list app-contract-tree-view .dx-datagrid-rowsview {
    padding-bottom: 0 !important;
}
