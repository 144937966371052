.ca-default-border {
  border-width: 1px;
  border-style: solid;
}

.ca-default-border-top {
  border-top-width: 1px;
  border-top-style: solid;
}

.ca-default-border-right {
  border-right-width: 1px;
  border-right-style: solid;
}

.ca-default-border-left {
  border-left-width: 1px;
  border-left-style: solid;
}

.ca-default-border-bottom {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.ca-default-border-color {
}

.ca-default-spacing-left {
  margin-left: $ca-form-flex-item-spacing;
}

.selection-badge {
  display: inline-block;
  padding: 6px 6px 6px 6px;
  opacity: 0.7;
  cursor: pointer;

  &.grid-badge,
  &.gl-selection-badge {
    border-radius: 0.25em;
    display: inline-block;
    font-size: 0.8571428571428571em;
    line-height: 0.525em;
    padding: 6px 6px 6px 6px;
    opacity: 0.7;
    cursor: pointer;
  }
}

.selection-badge.active {
  opacity: 1;
  font-weight: bold;
}

.grid-selection-badge-container {
  text-align: left;
  line-height: 1em !important;
}

.tabbed-content-no-scroll .tabs-body {
  overflow-y: hidden !important;
}

.ca-form {
  .ca-inline-field {
    .mat-mdc-form-field {
      padding-top: 0 !important;
    }
  }
}
