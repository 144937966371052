@function resizePager($value, $add: 0) {
  @return ($value + $add) * $pager-multiplier * 1px;
}

.picker-pager .mat-mdc-text-field-wrapper {
  padding-bottom: 0px !important;

  .mdc-text-field {
    align-items: center;
  }
}

.app-pager {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: auto;
  box-sizing: border-box;
  text-align: right;

  mat-toolbar-row,
  .mat-toolbar.mat-toolbar-single-row.pager-toolbar {
    height: resize(56) !important;
  }

  mat-toolbar {
    font-size: resizePager($ca-menu-typography) !important;
  }

  .pager-counter {
    padding-right: resizePager(48);
    font-size: $font_size_body2;
  }

  .pager-size-picker {
    padding-right: resizePager(30);
    p {
      margin: 0 !important;
      padding-right: resizePager(8);
      font-size: resizePager($ca-menu-typography);
    }
  }

  .mdc-icon-button {
    width: resizePager(40) !important;
    height: auto !important;
  }

  .pager-previous-btn {
    margin-right: resizePager(24);
  }

  .ca-form {
    min-height: auto !important;
  }

  [caInput] {
    .mat-input-underline {
      bottom: 0;
    }
    .mat-input-wrapper {
      padding-bottom: 0 !important;
    }

    &.picker-pager {
      height: 100%;
      .mat-mdc-form-field-flex {
        width: inherit !important;
      }
      .mdc-text-field {
        min-height: resizePager(56) !important;
        padding: 0px 16px !important;
        .mat-mdc-form-field-infix {
          padding: 0px !important;
          margin-left: 0px !important;
          mat-select {
            margin-top: 40%;
            .mat-mdc-select-value-text {
              line-height: resizePager(56) !important;

              span {
                font-size: resizePager($ca-menu-typography) !important;
              }
            }
          }
        }
      }
      .mat-mdc-form-field-subscript-wrapper {
        height: 0px;
      }
      .mat-mdc-form-field {
        padding-top: 0px;
      }
    }
  }
}

ca-pager {
  span {
    font-size: 12px;
    font-weight: 300;
  }
}

ca-pager-size {
  .mat-mdc-form-field.mat-primary {
    background-color: transparent !important;
  }
}

.page-size-select {
  ca-default-picker {
    width: resizePager(80) !important;
    min-width: resizePager(70);
  }
}

.pager-size-option {
  .mdc-list-item__primary-text {
    overflow: initial;
  }
  .mat-mdc-form-field-infix {
    padding-top: 0px !important;
  }
  .mat-option-text {
    width: resizePager(44) !important;
    text-align: left !important;
    font-size: $font_size_body2;
  }
}
