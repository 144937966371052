@use "@angular/material" as mat;
@use "../../assets/themes/default" as theme;

.btn-primary {
  color: mat.get-color-from-palette(theme.$app-primary, default-contrast) !important;
  background-color: mat.get-color-from-palette(theme.$app-primary) !important;
}

.mdc-button, .mat-primary {
  text-transform: uppercase;
}


.ca-dialog-action {

  &.ca-button-baseline-align {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }

  .mdc-button {
    min-width: 64px;
    margin: 8px 4px 0px 4px !important;
    padding: 0 8px !important;
    &[disabled] {
      opacity: 0.5;
    }
  }

  .mdc-button:last-child {
    margin-right: 0 !important;
  }
}

.demarc-form-action {
  text-align: right;
  padding-top: 26px;
  .mdc-button{
    min-width: unset;
    padding: 0 8px;
    margin: 0 8px;

    &[disabled] {
      opacity: 0.5;
    }
  }
}
