@function resizeBar($value, $add: 0) {
  @return ($value + $add) * $bar-multiplier * 1px;
}

mat-toolbar-row, .mat-toolbar.mat-toolbar-single-row {
  height: resizeBar(48) !important;
}

mat-toolbar {
  //min-height: resize(48) !important;
  font-weight: 300 !important;

  .toolbar-title, .toolbar-details-title {
    letter-spacing: 0.0075rem !important;
    font-size: resizeBar(20);
    line-height: resizeBar(20);
    font-weight: 500;
  }

  .mdc-icon-button {
    width: resizeBar(40) !important;
    height: resizeBar(40) !important;
    padding: 5px 0 0 !important;

    .material-icons {
      height: resizeBar(24);
      width: resizeBar(24);
      line-height: resizeBar(24);
      font-size: resizeBar(24);
    }
  }
}

mat-toolbar.toolbar-details {
  padding-left: 8px;
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.toolbar-title {
  //padding-left: 8px;
}

.toolbar-details-title {
  padding-top: 2px;
  padding-left: 4px;
}

mat-toolbar.border-top {
  border-top-width: 1px;
  border-top-style: solid;
}

mat-toolbar.border-bottom {
  border-bottom-width: 1px;
  border-bottom-style: solid;
}

.vertical-divider {
  border-top-width: 0;
  border-right-width: 1px;
  border-right-style: solid;
  height: 60%;
  margin: 10px;
  transform: perspective(1px) !important;
}

.ca-second-level-toolbar {
  padding-right: 24px !important;
  padding-left: 24px !important;
}

.app-content {
  position: absolute !important;
  top: resizeBar(48);
  left: 0;
  right: 0;
  bottom: 0;

  .app-content-body {
    padding: 15px;
  }

  .grid-container {
    .grid-toolbar {
      height: 40px;
    }

    .grid-body {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &.grid-with-pager {

      .grid-body {
        bottom: 50px;
      }
    }

    &.grid-with-toolbar {
      .grid-body {
        top: 50px;
      }
    }

    .grid-pager {
      height: 50px;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  &.gl-rule-builder-content {
    .rule-builder-body {
      position: absolute;
      top: 88px;
      left: 0;
      right: 0;
      bottom: 0;
      overflow-y: auto;

      .toolbar-card {
        margin: 0 !important;
        padding: 0 2em !important;
      }
    }

  }

}
